import { useDynamicFilterFields } from '@utils/hooks/useDynamicFilterFields';

import { useStaticFilterFields } from './useStaticFilterFields';

const useFilterFields = ({
  attributes,
  dataSources,
  isAttributesLoading,
  isFetchingDataSources,
  userRole,
}) => {
  const dynamicFields = useDynamicFilterFields({ attributes, isAttributesLoading });
  const staticFields = useStaticFilterFields({
    dataSources,
    isFetchingDataSources,
    userRole,
  });

  return {
    dynamic: dynamicFields,
    static: staticFields,
  };
};

export default useFilterFields;
