import { findIsUserRoleAuthorized, getUserRole } from '@common/auth';
import { getPathFragments, getRedirectLocationDescriptor } from '@common/routing';
import { liveVersionUrlPredicates, urlUserRoleMap } from '@constants/auth';
import { RouteUrls } from '@constants/routeUrls';
import BaseLayoutFallback from '@layouts/BaseLayout/BaseLayoutFallback';
import useAppNavigate from '@root/hooks/useAppNavigate';
import { useIsLiveAppVersion } from '@root/hooks/useIsLiveAppVersion';
import { useMeQuery } from '@services/api/hooks/useMeQuery';
import { useCurrentRoutePath } from '@utils/hooks/useCurrentRoutePath';
import { useIsLoggedIn } from '@utils/hooks/useIsLoggedIn';
import useScrollToTop from '@utils/hooks/useScrollToTop';
import { Navigate, useLocation, useOutlet } from 'react-router-dom';

/**
 * @param {import('react-router-dom').RouteProps} routeProps
 */
const ProtectedRoute = () => {
  const navigate = useAppNavigate();
  const isLoggedIn = useIsLoggedIn();
  const isLiveAppVersion = useIsLiveAppVersion();
  const outlet = useOutlet();
  const location = useLocation();
  const path = useCurrentRoutePath();

  const { me, isMeFetched } = useMeQuery({
    queryOptions: {
      enabled: isLoggedIn,
    },
  });
  const userRole = getUserRole({ user: me });

  const pathFragments = getPathFragments(location.pathname);
  const currentPath = pathFragments.mainPath;
  const isRouteAvailable =
    !isLiveAppVersion || liveVersionUrlPredicates.some((predicate) => predicate(currentPath));
  // Navigating away while t.state.idx === 0 throws user out of the app in react-router v6 https://github.com/remix-run/react-router/discussions/9922
  const hasNavigated = window.history?.state?.idx > 0;
  const isAtIndex = location.pathname === '/';
  const canNavigateBack = hasNavigated && !isAtIndex;

  const redirectTo = getRedirectLocationDescriptor({
    targetUrl: RouteUrls.public.signIn,
    redirectUrl: currentPath,
  });

  const isPageAuthorized = findIsUserRoleAuthorized({
    currentUserRole: userRole,
    authorizedUserRole: urlUserRoleMap[path],
  });

  useScrollToTop();

  if (!isRouteAvailable && canNavigateBack) {
    navigate(-1);
    return null;
  }

  if (!isLoggedIn) return <Navigate to={redirectTo} replace />;
  if (!isMeFetched) return <BaseLayoutFallback />;
  if (!isPageAuthorized) return <Navigate to={RouteUrls.errors.notFound} replace />;

  return outlet;
};

export default ProtectedRoute;
