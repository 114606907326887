import { AttributeDataType } from '@constants/commontypes';

import { useAttributeValuesMapQueries } from './useAttributeValuesMapQueries';

export function useAttributeOptionsMapQueries({ fields, queryOptions = {} }) {
  const queryOptionList = fields
    .filter((field) => field.data_type === AttributeDataType.DROPDOWN)
    .map((field) => ({ attribute: field?.id, ...queryOptions }));

  return useAttributeValuesMapQueries({
    queryOptionList,
  });
}
