import '../style.scss';

import { getFormRuleMap } from '@common/form';
import AkinonButton from '@components/AkinonButton';
import { openNotification } from '@components/akinonNotification';
import Box from '@components/utility/box/index';
import { QueryKey } from '@constants/query';
import DraggerInputWithLabel from '@pages/Finance/SellerManagement/SellerManagementDetail/components/common/DraggerInputWithLabel';
import { useBulkImportPricesMutation } from '@services/api/hooks/useBulkImportPricesMutation';
import { queryClient } from '@services/api/queryClient';
import { Form } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormField } from '../common';
import { BulkActionsProgressModal } from './BulkActionsProgressModal';

const BulkUpdatePrices = () => {
  const { t } = useTranslation('ProductsAndCategories');
  const formRuleMap = getFormRuleMap({ t });
  const [form] = Form.useForm();
  const {
    bulkImportPrices,
    resetBulkImportPrices,
    isBulkImportingPrices,
    isBulkImportPricesSuccess,
    isBulkImportPricesError,
    bulkImportPricesData,
  } = useBulkImportPricesMutation({
    mutationOptions: {
      onSuccess: async ({ key }) => {
        await queryClient.invalidateQueries({
          queryKey: [QueryKey.IMPORT_STATUS, key],
        });
      },
      onError: (error) => {
        openNotification({
          message: t('transaction_failed'),
          description:
            error.response?.data?.detail ?? error?.response?.data?.invalid_headers ?? error.message,
          type: 'error',
        });
      },
    },
  });

  const handleFileUpload = () => {
    form.validateFields([FormField.FILE]).then(({ file }) => {
      const formData = new FormData();
      file.fileList.forEach((fileItem) => {
        formData.append(FormField.FILE, fileItem.originFileObj);
      });
      bulkImportPrices({ formData });
    });
  };

  return (
    <>
      <div className="bulk-actions">
        <Box when className="box-primary form-box bulk" title={t('bulk_actions_prices')}>
          <Form className="ant-form" form={form} layout="vertical">
            <Form.Item
              name="file"
              rules={[formRuleMap.file()]}
              label={t('bulk_actions_upload_prices')}
            >
              <DraggerInputWithLabel
                accept=".csv,.xlsx,.xls"
                singleAction
                multiple={false}
                beforeUpload={() => false}
              />
            </Form.Item>

            <div className="actions">
              <AkinonButton
                loading={isBulkImportingPrices}
                style={{
                  marginTop: '24px',
                  fontWeight: '600',
                  fontSize: '14px',
                }}
                type="primary"
                onClick={handleFileUpload}
              >
                {t('save')}
              </AkinonButton>
            </div>
          </Form>
        </Box>
      </div>
      <BulkActionsProgressModal
        bulkImportKey={bulkImportPricesData?.key}
        open={isBulkImportPricesSuccess && !isBulkImportPricesError}
        onCancel={() => {
          resetBulkImportPrices();
          form.resetFields();
        }}
        messages={{
          successCount: ({ succeededCount }) =>
            t('price_import_progress_succeeded_count', { count: succeededCount }),
          failCount: ({ failedCount }) =>
            t('price_import_progress_failed_count', { count: failedCount }),
          finishButtonText: t('go_to_created_items', {
            to: t('to_price'),
          }),
        }}
      />
    </>
  );
};

export default BulkUpdatePrices;
