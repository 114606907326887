import { fillFilterFieldOptions } from '@components/AkinonFilter/components/FilterOptions/common';
import { AttributeDataType, attributeWidgetMap, WidgetType } from '@constants/commontypes';
import usePageData from '@hooks/usePageData';
import { useAttributeOptionsMapQueries } from '@services/api/hooks/useAttributeOptionsMapQueries';
import { cond, constant, matches, stubTrue } from 'lodash';
import { useState } from 'react';
import { useDeepCompareEffect } from 'react-use';

import { getDynamicFilterWidgetProps } from './common';

const generateDynamicFilterKey = ({ prefixPairs, suffixPairs, widget, name }) => {
  const dynamicKeyPrefix = cond(prefixPairs)(widget);
  const dynamicKeySuffix = cond(suffixPairs)(widget);

  return [dynamicKeyPrefix, name, dynamicKeySuffix].filter(Boolean).join('__');
};

export function useDynamicFilterFields({ attributes, isAttributesLoading }) {
  const { pageData } = usePageData();
  const visibleFilters = pageData?.visibleFilters ?? [];
  const [dynamicFilterFields, setDynamicFilterFields] = useState([]);

  const fields =
    attributes?.map(({ id, key, name, data_type }) => {
      const widget = attributeWidgetMap[data_type];
      const widgetProps = getDynamicFilterWidgetProps({ widget });

      const filterName = data_type !== AttributeDataType.DROPDOWN ? `${key}__icontains` : key;
      const dynamicFilterName = generateDynamicFilterKey({
        prefixPairs: [[stubTrue, constant('attributes')]],
        suffixPairs: [[matches(WidgetType.SELECT), constant('exact')]],
        widget,
        name: filterName,
      });

      return {
        id,
        name: dynamicFilterName,
        widget,
        data_type,
        options: [],
        placeholder: name,
        ...widgetProps,
      };
    }) ?? [];

  useAttributeOptionsMapQueries({
    fields,
    queryOptions: {
      enabled: false,
    },
  });

  useDeepCompareEffect(() => {
    if (!visibleFilters.length) return;
    (async () => {
      setDynamicFilterFields(await fillFilterFieldOptions({ fields, visibleFilters }));
    })();
  }, [attributes, visibleFilters]);

  return {
    fields: dynamicFilterFields,
    isLoading: isAttributesLoading,
  };
}
