import { openNotification } from '@components/akinonNotification';
import { approvePreOfferURL, submitPreOfferURL } from '@constants/apiUrls';
import { client } from '@services/api/client';

import { PreOfferStatus } from '../../common';

const useApproveOffers = ({ t, refetchOffers }) => {
  const approveSelectedItems = async ({ selectedRows, mutationOptions }) => {
    const { onSuccess, onError } = mutationOptions;

    const approvalRequiredSelectedRows = selectedRows.filter(
      (selectedRow) => selectedRow.approval_status === PreOfferStatus.APPROVAL_REQUIRED
    );

    const approvePreOfferPromises = approvalRequiredSelectedRows.map((row) => {
      return () => client.post(submitPreOfferURL, {}, { urlParams: { id: row.id } });
    });

    const approvalPromises = selectedRows.map((row) => {
      return () =>
        client
          .post(approvePreOfferURL, {}, { urlParams: { id: row.id } })
          .then((result) => {
            if (selectedRows.length === 1) {
              openNotification({
                message: t('success'),
                description: t('transaction_success'),
                type: 'success',
              });
            }
            onSuccess(result);
          })
          .catch((error) => {
            if (selectedRows.length === 1) {
              openNotification({
                message: error?.message ?? t('error'),
                description:
                  error.response?.data?.detail ??
                  error.response?.data?.non_field_errors ??
                  error.message,
                type: 'error',
              });
            }
            onError(error);
          });
    });

    await Promise.allSettled(approvePreOfferPromises.map((f) => f()));
    await Promise.allSettled(approvalPromises.map((f) => f()));
    refetchOffers();
  };

  return { approveSelectedItems };
};

export default useApproveOffers;
