import { AttributeDataType, booleanAttributeOptions, WidgetType } from '@constants/commontypes';
import { QueryKey } from '@constants/query';
import { queryClient } from '@services/api/queryClient';
import cloneDeep from 'lodash/cloneDeep';

export const fillFilterFieldOptions = async ({ fields, visibleFilters }) => {
  const fetchQuery = ({ key, id }) => {
    return queryClient.fetchQuery({
      queryKey: [key, id],
      staleTime: Infinity,
    });
  };

  const filledFields = await Promise.all(
    fields.map(async (field) => {
      const newField = cloneDeep(field);

      if (!visibleFilters.includes(field.name)) {
        return field;
      }

      switch (field.data_type) {
        case AttributeDataType.BOOLEAN:
          newField.widget = WidgetType.SELECT;
          newField.options = booleanAttributeOptions;
          break;
        case AttributeDataType.DROPDOWN:
          const response = await fetchQuery({
            key: QueryKey.ATTRIBUTE_VALUES,
            id: newField.id,
          });
          newField.options = response?.results ?? [];
          break;
        default:
          return field;
      }

      return newField;
    })
  );

  return filledFields;
};
