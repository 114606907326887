import { createSelectOptions } from '@common/index';
import { WidgetType } from '@constants/commontypes';
import { useTranslation } from 'react-i18next';

import { StaticFilterKey } from '../common';

export function useStaticFilterFields({ product, categories, isCategoriesLoading }) {
  const { t } = useTranslation();

  return {
    fields: [
      {
        name: StaticFilterKey.NAME,
        widget: WidgetType.INPUT,
        placeholder: t('name'),
      },
      {
        name: StaticFilterKey.BASE_CODE,
        widget: WidgetType.INPUT,
        placeholder: t('base.code'),
      },
      {
        name: StaticFilterKey.SKU,
        widget: WidgetType.INPUT,
        placeholder: t('sku'),
        initialValue: product?.sku,
      },
      {
        name: StaticFilterKey.CATEGORY,
        widget: WidgetType.SELECT,
        placeholder: t('category'),
        loading: isCategoriesLoading,
        options: createSelectOptions(categories, {
          valueKey: 'id',
          labelKey: 'name',
        }),
      },
    ],
  };
}
