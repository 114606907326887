import { useDynamicFilterFields } from '@utils/hooks/useDynamicFilterFields';

import useStaticFilterFields from './useStaticFilterFields';

const useFilterFields = ({ t, attributes, isAttributesLoading }) => {
  const dynamicFields = useDynamicFilterFields({ attributes, isAttributesLoading });
  const staticFields = useStaticFilterFields({ t });

  return {
    dynamic: dynamicFields,
    static: staticFields,
  };
};

export default useFilterFields;
