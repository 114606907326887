import AkinonFilter from '@components/AkinonFilter';
import { usePageFilters } from '@components/AkinonFilter/hooks/store/usePageFilters';
import AkinonTable from '@components/AkinonTable';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import { defaultRowKey } from '@constants/index';
import { useAttributesQuery } from '@services/api/hooks/useAttributesQuery';
import { useCategoriesQuery } from '@services/api/hooks/useCategoriesQuery';
import { useProductQuery } from '@services/api/hooks/useProductQuery';
// import { useProductQuery } from '@services/hooks';
import useResetTableCurrentPageWhenFiltersChanged from '@utils/hooks/useResetTableCurrentPageWhenFiltersChanged';
import useStore from '@zustand-store/index';
import { setSelectedRelatedProductSelector } from '@zustand-store/selectors/preOffer';
import { first } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getColumns } from './common';
import { useDataSource } from './hooks/useDataSource';
import useFilterFields from './hooks/useFilterFields';

const RelatedProductsTable = ({ categories, preOffer }) => {
  const { t } = useTranslation('ProductsAndCategories');
  const { product } = useProductQuery({
    productId: preOffer?.product,
    queryOptions: { enabled: Boolean(preOffer?.product) },
  });
  const filters = usePageFilters();
  const setSelectRelatedProduct = useStore(setSelectedRelatedProductSelector);
  const [pagination, setPagination] = usePagination();
  const { dataSource, isDataSourceLoading, total } = useDataSource({ pagination, filters });
  const { categories: categoryTreeData, isCategoryTreeDataLoading } = useCategoriesQuery();

  const { attributes, isAttributesLoading } = useAttributesQuery();
  const filterFields = useFilterFields({
    product,
    categories: categoryTreeData,
    isCategoriesLoading: isCategoryTreeDataLoading,
    attributes,
    isAttributesLoading,
  });

  useResetTableCurrentPageWhenFiltersChanged({ pagination, setPagination, filters });

  const columns = getColumns({ t, categories });
  const rowSelection = {
    type: 'radio',
    onChange(_selectedRowKeys, selectedRows) {
      setSelectRelatedProduct(first(selectedRows) ?? null);
    },
  };

  return (
    <section className="related-products">
      <AkinonFilter filterFields={filterFields} />
      <AkinonTable
        title={t('relatable.products')}
        columns={columns}
        dataSource={dataSource}
        total={total}
        rowKey={defaultRowKey}
        pagination={pagination}
        loading={isDataSourceLoading}
        setPagination={setPagination}
        description={`${total} ${t('results.found')}`}
        rowSelection={rowSelection}
      />
    </section>
  );
};

export default RelatedProductsTable;
