export const getDynamicColumns = ({ visibleFilters = [], dynamicFilterFields = [] }) => {
  const dynamicFilters = visibleFilters.filter((filterKey) =>
    dynamicFilterFields?.map?.((filterField) => filterField.name).includes(filterKey)
  );

  const dynamicColumns = [],
    // TODO rename dynamicFormMetaFields to something better
    dynamicFormMetaFields = [];

  dynamicFilters.forEach((dynamicFilterKey) => {
    const filterField = dynamicFilterFields.find(
      (filterField) => filterField.name === dynamicFilterKey
    );

    dynamicFormMetaFields.push({ ...filterField, fieldKey: dynamicFilterKey });

    dynamicColumns.push({
      title: filterField.placeholder,
      dataIndex: ['attributes', dynamicFilterKey],
      key: dynamicFilterKey,
      classname: 'uppercase',
      render: (data) => data ?? '-',
    });
  });
  return { dynamicColumns, dynamicFormMetaFields };
};
