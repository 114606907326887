import './style.scss';

import { useUserRole } from '@utils/hooks/useUserRole';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ItemList } from '../../common/ItemList';
import { getColumns } from './common';
import { GeneralInfo } from './GeneralInfo';
import useFilterFields from './hooks/useFilterFields';
import { useMergedInfo } from './hooks/useMergedInfo';

const ReconciliationDetail = () => {
  const { t } = useTranslation('Finance');
  const { reconciliationId } = useParams();
  const { reconciliation } = useMergedInfo({ reconciliationId });
  const userRole = useUserRole();
  const columns = getColumns({ t, reconciliation, userRole });
  const filterFields = useFilterFields({ userRole });

  return (
    <section className="reconciliation-detail">
      <GeneralInfo reconciliation={reconciliation} />
      <ItemList columns={columns} filterFields={filterFields} reconciliationId={reconciliationId} />
    </section>
  );
};

export default ReconciliationDetail;
