import { sleep } from '@utils/index';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useRef, useState } from 'react';
import { useCounter } from 'react-use';

const useBulkAction = ({ bulkActionMutate, isSingleRequest = false }) => {
  const setSelectedRowsRef = useRef();
  const [selectedRows, setSelectedRows] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isProgressModalVisible, setIsProgressModalVisible] = useState(false);
  const [failedRequestCount, { inc: incFailedRequestCount, reset: resetFailedRequestCount }] =
    useCounter(0);
  const [
    successfulRequestCount,
    { inc: incSuccessfulRequestCount, reset: resetSuccessfulRequestCount },
  ] = useCounter(0);

  const onShowModal = (_selectedRows, _setSelectedRows) => {
    if (isEmpty(_selectedRows)) return;
    setSelectedRows(_selectedRows);
    setSelectedRowsRef.current = _setSelectedRows;
    setIsModalVisible(true);
  };
  const onCloseModal = () => {
    setIsModalVisible(false);
    setSelectedRowsRef.current = null;
  };
  const onCloseProgressModal = () => {
    setIsProgressModalVisible(false);
    resetFailedRequestCount();
    resetSuccessfulRequestCount();
    setSelectedRowsRef.current?.([]);
    setSelectedRowsRef.current = null;
  };
  const onStartBulkAction = (additionalParams = {}) => {
    setIsModalVisible(false);
    setIsProgressModalVisible(true);
    bulkActionMutate({
      selectedRows,
      ...additionalParams,
      mutationOptions: {
        onSuccess: () => incSuccessfulRequestCount(),
        onError: () => incFailedRequestCount(),
      },
    });
  };

  const isSingleRow = selectedRows?.length === 1;
  const totalRequestCount = isSingleRequest ? 1 : selectedRows?.length;

  useEffect(() => {
    const totalRequestCount = isSingleRequest ? 1 : selectedRows?.length;
    const isFinished = failedRequestCount + successfulRequestCount === totalRequestCount;
    const isFailed = failedRequestCount > 0;
    if (isFinished && !isFailed) {
      const waitTime = 5000;
      sleep(waitTime).then(onCloseProgressModal);
    }
  }, [failedRequestCount, successfulRequestCount, selectedRows]);

  return {
    onShowModal,
    isModalVisible,
    isProgressModalVisible: isSingleRow ? false : !isModalVisible && isProgressModalVisible,
    onCloseModal,
    onCloseProgressModal,
    failedRequestCount,
    successfulRequestCount,
    totalRequestCount,
    onStartBulkAction,
  };
};

export default useBulkAction;
