import './style.scss';

import { getRequestFiltersParams, getRequestPaginationParams } from '@common/index';
import AkinonFilter from '@components/AkinonFilter';
import { usePageFilters } from '@components/AkinonFilter/hooks/store/usePageFilters';
import AkinonTable from '@components/AkinonTable';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import BulkActionModal from '@components/BulkActionModal';
import { getConversationsUrl } from '@constants/apiUrls';
import { UserRole } from '@constants/auth';
import { RouteUrls } from '@constants/routeUrls';
import { useTour } from '@reactour/tour';
import useAppNavigate from '@root/hooks/useAppNavigate';
import useBulkAction from '@root/hooks/useBulkAction';
import { useCreateConversationStatusMutation } from '@services/api/hooks/useCreateConversationStatusMutation';
import { useUserRole } from '@utils/hooks/useUserRole';
import { Divider } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ConversationType } from '../common';
import useChangeMessagePrivacyStatus from '../OrderMessages/hooks/useChangeMessagePrivacyStatus';
import { getColumns, getTableActions, getTourSteps } from './common';
import { useProductMessagesQuery } from './hooks/api/useProductMessagesQuery';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import useFilterFields from './hooks/useFilterFields';
import { usePageMeta } from './hooks/usePageMeta';

function ProductMessages() {
  const { t } = useTranslation('Messages');
  const navigate = useAppNavigate();
  const [pagination, setPagination] = usePagination();
  const filters = usePageFilters();
  const { productMessages, isProductMessagesLoading, productMessagesTotal } =
    useProductMessagesQuery({
      requestConfig: {
        params: {
          ...getRequestPaginationParams(pagination),
          ...getRequestFiltersParams(filters),
          item_type: ConversationType.PRODUCT,
        },
      },
    });
  const { createConversationStatusAsync } = useCreateConversationStatusMutation();
  const { onMakePublic, onMakePrivate } = useChangeMessagePrivacyStatus({
    createConversationStatusAsync,
  });
  const { onShowModal: onShowMakePublicModal, ...makePublicBulkActionProps } = useBulkAction({
    bulkActionMutate: onMakePublic,
  });
  const { onShowModal: onShowMakePrivateModal, ...makePrivateBulkActionProps } = useBulkAction({
    bulkActionMutate: onMakePrivate,
  });

  const userRole = useUserRole();
  const isSuperUser = userRole === UserRole.SUPER_USER;

  const { setSteps } = useTour();
  useEffect(() => {
    setSteps(
      getTourSteps({
        t,
      })
    );
  }, [t]);

  useBreadcrumbs();
  usePageMeta();

  const columns = getColumns({ t, isSuperUser });
  const tableActions = getTableActions({
    t,
    isSuperUser,
    onShowMakePublicModal,
    onShowMakePrivateModal,
  });

  const onRow = (record) => ({
    onClick: () => {
      navigate(RouteUrls.messages.productMessagesForm, { id: record.id });
    },
  });

  const filterFields = useFilterFields({ t });

  return (
    <section className="product-messages" loading={isProductMessagesLoading}>
      <AkinonFilter title={t('filters')} filterFields={filterFields} total={productMessagesTotal} />
      <Divider />
      <AkinonTable
        title={t('product.messages')}
        rowKey={'id'}
        dataSource={productMessages}
        optionsUrl={getConversationsUrl}
        exportable={false}
        columns={columns}
        total={productMessagesTotal}
        onRow={onRow}
        tableActions={tableActions}
        description={`${productMessagesTotal} ${t('results.found')}`}
        pagination={pagination}
        setPagination={setPagination}
        rowSelection={isSuperUser}
      />

      <BulkActionModal
        {...makePublicBulkActionProps}
        modalProps={{
          title: t('bulk.make.messages.public'),
          description: t('bulk.make.messages.public.desc'),
        }}
        progressModalProps={{ title: t('bulk.make.messages.public') }}
      />
      <BulkActionModal
        {...makePrivateBulkActionProps}
        modalProps={{
          title: t('bulk.make.messages.private'),
          description: t('bulk.make.messages.private.desc'),
        }}
        progressModalProps={{ title: t('bulk.make.messages.private') }}
      />
    </section>
  );
}

export default ProductMessages;
