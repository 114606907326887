import { createSelectOptions } from '@common/index';
import { UserRole } from '@constants/auth';
import { getCancellationPlanStatuses, WidgetType } from '@constants/commontypes';
import { useDataSourcesQuery } from '@services/api/hooks/usePaginatedDataSourcesQuery';
import { useUserRole } from '@utils/hooks/useUserRole';
import { useTranslation } from 'react-i18next';

import { orderStatuses, StaticFilterKey } from '../common';

export const useStaticFilterFields = () => {
  const { t } = useTranslation('Orders');
  const { dataSources, isFetchingDataSources } = useDataSourcesQuery();
  const userRole = useUserRole();
  const isSuperUser = userRole === UserRole.SUPER_USER;

  const dataSourcesOptions = createSelectOptions(dataSources, {
    labelKey: 'name',
    valueKey: 'id',
  });
  return {
    fields: [
      isSuperUser && {
        name: StaticFilterKey.SELLER,
        widget: WidgetType.SELECT,
        options: dataSourcesOptions,
        loading: isFetchingDataSources,
        placeholder: t('seller'),
      },
      {
        name: StaticFilterKey.SHIPPING_ADDRESS.EMAIL,
        placeholder: t('email'),
      },
      {
        name: StaticFilterKey.SKU,
        placeholder: t('sku'),
      },
      {
        name: StaticFilterKey.BASE_CODE,
        placeholder: t('base.code'),
      },
      {
        name: StaticFilterKey.TRACKING_NUMBER,
        placeholder: t('trackingNumber'),
      },
      {
        name: StaticFilterKey.NUMBER,
        placeholder: t('order.number'),
      },
      {
        name: StaticFilterKey.DATE_GTE,
        widget: WidgetType.DATE_PICKER,
        placeholder: t('first_order_date'),
      },
      {
        name: StaticFilterKey.DATE_LTE,
        widget: WidgetType.DATE_PICKER,
        placeholder: t('last_order_date'),
      },
      {
        name: StaticFilterKey.STATUS,
        widget: WidgetType.SELECT,
        placeholder: t('order.status'),
        options: orderStatuses({ t }),
        showSearch: true,
        optionFilterProp: 'label',
      },
      {
        name: StaticFilterKey.CANCELLATION,
        widget: WidgetType.SELECT,
        placeholder: t('cancellation_plan_status'),
        options: getCancellationPlanStatuses({ t }),
        showSearch: true,
        optionFilterProp: 'label',
      },
      {
        name: StaticFilterKey.AMOUNT_GTE,
        placeholder: t('min_order_amount'),
      },
      {
        name: StaticFilterKey.AMOUNT_LTE,
        placeholder: t('max_order_amount'),
      },
      {
        name: StaticFilterKey.DISCOUNT_AMOUNT_GTE,
        placeholder: t('min_discount_amount'),
      },
      {
        name: StaticFilterKey.DISCOUNT_AMOUNT_LTE,
        placeholder: t('max_discount_amount'),
      },
      {
        name: StaticFilterKey.FIRST_NAME,
        placeholder: t('customer_first_name'),
      },
      {
        name: StaticFilterKey.LAST_NAME,
        placeholder: t('customer.last.name'),
      },
      {
        name: StaticFilterKey.GIFT_BOX,
        widget: WidgetType.CHECKBOX_GROUP,
        placeholder: t('gift_note_question'),
        options: [{ label: t('gift_note_question'), key: StaticFilterKey.GIFT_BOX, value: false }],
      },
    ].filter(Boolean),
  };
};
