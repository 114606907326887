import { findElementByInnerText, getTourSelectorWithFallback } from '@common/index';
import AkinonButton from '@components/AkinonButton';
import Intrinsic from '@components/Intrinsic';
import Link from '@components/Link';
import Show from '@components/Show';
import SmallColumnImage from '@components/SmallColumnImage';
import {
  cancellationPlanStatuses,
  orderCancelColorsEnum,
  orderCancelStatuses,
  orderStatusColorsEnum,
} from '@constants/commontypes';
import { ProductStatus, StatusText } from '@pages/Orders/common';
import i18n from '@root/i18n';
import IconCubeSend from '@tabler/icons-react/dist/esm/icons/IconCubeSend.mjs';
import IconPackage from '@tabler/icons-react/dist/esm/icons/IconPackage.mjs';
import IconPackageImport from '@tabler/icons-react/dist/esm/icons/IconPackageImport.mjs';
import IconPackages from '@tabler/icons-react/dist/esm/icons/IconPackages.mjs';
import IconTruckDelivery from '@tabler/icons-react/dist/esm/icons/IconTruckDelivery.mjs';
import { dateFormatter, statusRenderer } from '@utils/index';
import { Tag, Tooltip, Typography } from 'antd';
import clsx from 'clsx';
import upperFirst from 'lodash/upperFirst';
import React from 'react';

const { Text, Title } = Typography;

const DataIndex = {
  IMG: 'image',
  PRODUCT_OBJ: 'product_obj',
  PRODUCT_OFFER_OBJ: 'product_offer_obj',
  NAME: 'name',
  PRICE: 'price',
  RETAIL_PRICE: 'retail_price',
  DISCOUNT_AMOUNT: 'discount_amount',
  TAX_RATE: 'tax_rate',
  STATUS: 'status',
  CANCEL_STATUS: 'cancel_status',
  SKU: 'sku',
  PK: 'pk',
  ID: 'id',
  PLAN_TYPE: 'plan_type',
  REFUND_AMOUNT: 'refund_amount',
  INVOICE_NUMBER: 'invoice_number',
  CREATED_AT: 'created_at',
  BILL_NUMBER: 'invoice_number',
  REFUND_ACTIONS: 'refund_actions',
};

export const Step = {
  ALL_PRODUCTS: 0,
  PENDING_SHIPMENT: 1,
  DELIVERED_TO_SHIPPING: 2,
  DELIVERED: 3,
  CANCELLATION_AND_RETURNED: 4,
};

export const getProductDetailSteps = ({ t }) => [
  {
    title: t('all_products'),
    icon: <IconPackages stroke="1" />,
    productStatus: ProductStatus.ALL,
    key: Step.ALL_PRODUCTS,
  },
  {
    title: StatusText({ t })?.[ProductStatus.PENDING_PACKAGING],
    icon: <IconPackage stroke="1" />,
    productStatus: ProductStatus.PENDING_PACKAGING,
    key: Step.PENDING_SHIPMENT,
  },
  {
    title: StatusText({ t })?.[ProductStatus.DELIVERED_TO_SHIPPING],
    icon: <IconTruckDelivery stroke="1" />,
    productStatus: ProductStatus.DELIVERED_TO_SHIPPING,
    key: Step.DELIVERED_TO_SHIPPING,
  },
  {
    title: StatusText({ t })?.[ProductStatus.DELIVERED_TO_CUSTOMER],
    icon: <IconCubeSend stroke="1" />,
    productStatus: ProductStatus.DELIVERED_TO_CUSTOMER,
    key: Step.DELIVERED,
  },
  {
    title: StatusText({ t })?.[ProductStatus.CANCELLED_AND_RETURNED],
    icon: <IconPackageImport stroke="1" />,
    productStatus: ProductStatus.CANCEL_TYPE_STATUSES,
    key: Step.CANCELLATION_AND_RETURNED,
  },
];

export const getTableActions = ({
  t,
  currentStep,
  onShowApproveRefundRequestsModal,
  onShowRejectRefundRequestsModal,
  onShowRejectOrderItemsModal,
  sellerDetail,
  dataSource,
}) => {
  const RejectActions = {
    allowedSteps: [Step.PENDING_SHIPMENT],
    canHandle: () => RejectActions.allowedSteps.includes(currentStep),
    getActions: () =>
      RejectActions.canHandle()
        ? [
            {
              label: t('reject'),
              actionHandler: (selectedRows) => onShowRejectOrderItemsModal(selectedRows),
              isSelectionRequired: true,
            },
          ]
        : null,
  };

  const RefundCancelActions = {
    allowedSteps: [Step.DELIVERED, Step.DELIVERED_TO_SHIPPING, Step.ALL_PRODUCTS],
    canHandle: () => {
      const hasCancelRequest = dataSource?.some?.((product) => {
        return product?.orderCancellationRequests?.some(
          (request) =>
            request.cancellation_type === 'cancel' &&
            request.status === cancellationPlanStatuses.WAITING
        );
      });

      const hasRefundRequest = dataSource?.some?.((product) => {
        return product?.orderCancellationRequests?.some(
          (request) =>
            request.cancellation_type === 'refund' &&
            request.status === cancellationPlanStatuses.WAITING
        );
      });

      const canCancel = hasCancelRequest && sellerDetail?.cancel_management_type;

      return (
        RefundCancelActions.allowedSteps.includes(currentStep) && (hasRefundRequest || canCancel)
      );
    },
    getActions: () =>
      RefundCancelActions.canHandle()
        ? [
            {
              label: t('approve_selected_refund_requests'),
              actionHandler: (selectedRows, setSelectedRows) =>
                onShowApproveRefundRequestsModal(selectedRows, setSelectedRows),
              isSelectionRequired: true,
            },
            {
              label: t('reject_selected_refund_requests'),
              actionHandler: (selectedRows, setSelectedRows) =>
                onShowRejectRefundRequestsModal(selectedRows, setSelectedRows),
              isSelectionRequired: true,
            },
          ]
        : null,
  };

  return [...(RejectActions.getActions() ?? []), ...(RefundCancelActions.getActions() ?? [])];
};

export const getProductDetailsColumns = ({
  t,
  onOpenSingleRejectModal,
  productStatus,
  language_code,
  packageItemsIds,
  handleProductInfoClick,
  isMobile,
}) => {
  return [
    {
      title: <Intrinsic className="icon-image" />,
      dataIndex: [DataIndex.IMG, DataIndex.IMG],
      render: (image) => <SmallColumnImage src={image} />,
    },
    {
      title: t('product_name'),
      dataIndex: [DataIndex.PRODUCT_OBJ, DataIndex.NAME],
      className: 'order-detail__product-name-column',
      render: (name, _record, index) => {
        const isFirstRow = index === 0;
        return (
          <Tooltip title={name}>
            <div className={clsx({ 'order-detail__product-name-column--first-row': isFirstRow })}>
              {name}
            </div>
          </Tooltip>
        );
      },
      onCell: (record) => ({
        onClick: () => handleProductInfoClick(record),
      }),
    },
    {
      title: t('offer_code'),
      dataIndex: [DataIndex.PRODUCT_OFFER_OBJ, DataIndex.SKU],
      render: (sku) => <Tooltip title={sku}>{sku}</Tooltip>,
    },
    {
      title: t('price'),
      dataIndex: DataIndex.PRICE,
      render: (price, rowData) => {
        return new Intl.NumberFormat(language_code, {
          style: 'currency',
          currency: rowData.price_currency,
        }).format(price);
      },
    },
    {
      title: t('retail.price'),
      dataIndex: DataIndex.RETAIL_PRICE,
      render: (price, rowData) => {
        return new Intl.NumberFormat(language_code, {
          style: 'currency',
          currency: rowData.price_currency,
        }).format(price);
      },
    },
    {
      title: t('discount'),
      dataIndex: DataIndex.DISCOUNT_AMOUNT,
      render: (price, rowData) => {
        return new Intl.NumberFormat(language_code, {
          style: 'currency',
          currency: rowData.price_currency,
        }).format(price);
      },
    },
    {
      title: t('tax'),
      dataIndex: DataIndex.TAX_RATE,
      render: (price) => {
        return new Intl.NumberFormat(language_code, {
          style: 'percent',
        }).format(parseFloat(price) / 100);
      },
    },
    {
      title: t('status'),
      dataIndex: DataIndex.STATUS,
      render: (status) => {
        return (
          <Tooltip title={StatusText({ t })?.[status]}>
            <Tag color={orderStatusColorsEnum[status]}>{StatusText({ t })?.[status]}</Tag>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: DataIndex.BILL_NUMBER,
      title: t('invoice_number'),
      render(billNumber, rowData) {
        return (
          <Link to={rowData.e_archive_url} target="_blank">
            {billNumber}
          </Link>
        );
      },
    },
    ...(productStatus === ProductStatus.PENDING_PACKAGING
      ? [
          {
            dataIndex: DataIndex.CANCEL_STATUS,
            title: t('action'),
            render: (value, record) => {
              const isPackageReadyToShip = packageItemsIds?.includes(record.id);
              const isOrderHasCancellationRequest = record?.orderCancellationRequests?.length > 0;

              return (
                <Show when={!isPackageReadyToShip && !isOrderHasCancellationRequest}>
                  <div className="reject-product">
                    <AkinonButton
                      type="danger"
                      onClick={(event) => {
                        event.stopPropagation();
                        onOpenSingleRejectModal({ orderItemId: record.id });
                      }}
                    >
                      {t('deny')}
                    </AkinonButton>
                  </div>
                </Show>
              );
            },
            fixed: isMobile ? false : 'right',
            width: 175,
          },
        ]
      : []),
  ];
};

export const cardBoxItems = ({ orderDetail = {}, t }) => [
  {
    header: t('total_order_amount').toUpperCase(),
    content:
      orderDetail?.currency &&
      new Intl.NumberFormat(orderDetail?.language_code, {
        style: 'currency',
        currency: orderDetail?.currency,
      }).format(orderDetail?.amount),
  },
  {
    header: t('total_discount_amount').toUpperCase(),
    content:
      orderDetail?.currency &&
      new Intl.NumberFormat(orderDetail?.language_code, {
        style: 'currency',
        currency: orderDetail?.currency,
      }).format(orderDetail?.discount_amount),
  },
  {
    header: t('total_shipping_amount').toUpperCase(),
    content:
      orderDetail?.currency &&
      new Intl.NumberFormat(orderDetail?.language_code, {
        style: 'currency',
        currency: orderDetail?.currency,
      }).format(orderDetail?.shipping_amount),
  },
  {
    header: t('order.date').toUpperCase(),
    content:
      orderDetail?.date_placed &&
      new Intl.DateTimeFormat().format(new Date(orderDetail?.date_placed)),
  },
];

export const cancellationPlanColumns = ({ t }) =>
  [
    {
      dataIndex: DataIndex.PLAN_TYPE,
      title: t('plan_type'),
      render: (planType) => upperFirst(planType),
    },
    {
      dataIndex: DataIndex.REFUND_AMOUNT,
      title: t('refund_amount'),
    },
    {
      dataIndex: DataIndex.STATUS,
      title: t('cancel_status'),
      render: (status) => {
        const statusText = statusRenderer(orderCancelStatuses({ t }), status);
        return (
          <Tooltip title={statusText}>
            <Tag color={orderCancelColorsEnum[status]}>{statusText}</Tag>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: DataIndex.CREATED_AT,
      title: t('created_date'),
      render: (value) => dateFormatter(value, true),
    },
  ]?.map((item, key) => ({ ...item, key }));

export const getTourSteps = ({ t, cancellationDataSource }) => {
  return [
    {
      selector: '.order-no-box',
      content: (
        <>
          <Title level={4}>{t('order_detail_tutorial.order_no')}</Title>
          <Text>{t('order_detail_tutorial.order_no_desc')}</Text>
        </>
      ),
    },
    {
      selector: '.order-main-information',
      content: (
        <>
          <Title level={4}>{t('order_detail_tutorial.sales_table')}</Title>
          <Text>{t('order_detail_tutorial.sales_table_desc')}</Text>
        </>
      ),
    },
    {
      selector: '.order-detail-info-boxes',
      content: (
        <>
          <Title level={4}>{t('order_detail_tutorial.sales_information')}</Title>
          <Text>{t('order_detail_tutorial.sales_information_desc')}</Text>
        </>
      ),
    },
    {
      selector: '.shipping-info-box',
      content: (
        <>
          <Title level={4}>{t('order_detail_tutorial.shipping')}</Title>
          <Text>{t('order_detail_tutorial.shipping_desc')}</Text>
        </>
      ),
    },
    {
      selector: '.akinon-steps',
      content: (
        <>
          <Title level={4}>{t('order_detail_tutorial.order_items')}</Title>
          <Text>{t('order_detail_tutorial.order_items_desc')}</Text>
        </>
      ),
    },
    {
      selector: '.akinon-table-actions',
      content: (
        <>
          <Title level={4}>{t('order_detail_tutorial.select_action')}</Title>
          <Text>{t('order_detail_tutorial.select_action_desc')}</Text>
        </>
      ),
    },
    {
      selector: getTourSelectorWithFallback(
        ['.order-detail__product-name-column--first-row'],
        findElementByInnerText(t('product_name').toLocaleUpperCase(i18n.language), 'th')
      ),
      content: (
        <>
          <Title level={4}>{t('product_name')}</Title>
          <Text>{t('order_detail_tutorial.product_name_desc')}</Text>
        </>
      ),
    },
    cancellationDataSource?.length > 0 && {
      selector: '.order-detail-refund-requests',
      content: (
        <>
          <Title level={4}>{t('cancel_refund_requests')}</Title>
          <Text>{t('order_detail_tutorial.cancel_refund_requests_desc')}</Text>
        </>
      ),
    },
    {
      selector: '.order-amount-info-box',
      content: (
        <>
          <Title level={4}>{t('order_detail_tutorial.order_amount_table')}</Title>
          <Text>{t('order_detail_tutorial.order_amount_table_desc')}</Text>
        </>
      ),
    },
  ].filter(Boolean);
};

export { ProductStatus, StatusText };
