import { getStaticFilters } from '../common';

const useFilterFields = ({
  t,
  staticFilterNameKey,
  shippingCompanies,
  shippingMethodOptions,
  dataSources,
  isSuperUser,
}) => {
  const staticFilters = getStaticFilters({
    t,
    staticFilterNameKey,
    shippingCompanies,
    shippingMethodOptions,
    dataSources,
    isSuperUser,
  });

  return {
    dynamic: { fields: [], isLoading: false },
    static: staticFilters,
  };
};

export default useFilterFields;
