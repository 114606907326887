import { PluginRenderer, useAppShell } from '@akinon/app-shell';
import { QueryParamsBuilder } from '@common/query-params-builder';
import AkinonFilter from '@components/AkinonFilter';
import { usePageFilters } from '@components/AkinonFilter/hooks/store/usePageFilters';
import { openNotification } from '@components/akinonNotification';
import AkinonTable from '@components/AkinonTable';
import { ExportFormat } from '@components/AkinonTable/components/StandardAkinonTableHeader/ExportModal';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import { Animations } from '@components/Animate/common';
import Show from '@components/Show';
import { exportPackageItemsUrl, exportPackagesUrl, listPackagesUrl } from '@constants/apiUrls';
import { UserRole } from '@constants/auth';
import { FileExtension, PackageStatus } from '@constants/commontypes';
import { defaultRowKey } from '@constants/index';
import { QueryKey } from '@constants/query';
import { client } from '@services/api/client';
import { queryClient } from '@services/api/queryClient';
import { useUserRole } from '@utils/hooks/useUserRole';
import { Divider } from 'antd';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useBoolean } from 'react-use';

import { getColumns, StaticFiltersNameKey } from '../common';
import BulkUpdatePackages from '../components/BulkUpdatePackages';
import { useDataSource, usePackageStatuses } from '../hooks';
import useFilterFields from '../hooks/useFilterFields';
import { ExportOption } from '../PackageList/common';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { usePageMeta } from './hooks/usePageMeta';

const PackagesWaitingForDelivery = () => {
  const { t } = useTranslation('Orders');
  const { configs } = useAppShell();
  const configArray = [...configs.values()];
  const isGenerateShipmentLabelPluginRegistered = configArray.some(
    (config) => config.placeholderId === 'ui-protocol-generate-shipment-label'
  );
  const [params, setParams] = useState();
  const userRole = useUserRole();
  const isSuperUser = userRole === UserRole.SUPER_USER;
  const filters = usePageFilters();
  const [pagination, setPagination] = usePagination();
  const { packageStatuses, mutatePackageStatuses } = usePackageStatuses();
  const [isBulkUpdatePackagesActive, toggleIsBulkUpdatePackagesActive] = useBoolean(false);
  const {
    dataSource,
    isDataSourceLoading,
    total,
    shippingCompanies,
    shippingMethodOptions,
    dataSources,
  } = useDataSource({
    pagination,
    filters,
    params: {
      status: PackageStatus.SHIPPED,
    },
    isSuperUser,
  });

  const onExportSuccess = () => {
    openNotification({
      type: 'success',
      message: t('file.preparing'),
      description: t('file.download.modal.description'),
    });
  };

  const onExportPackagesAsXlsx = ({ fieldsToExport }) => {
    const params = {
      ...filters,
      ...QueryParamsBuilder.new()
        .set('extension', FileExtension.XLSX)
        .set('status', PackageStatus.SHIPPED)
        .set('fields', isEmpty(fieldsToExport) ? undefined : fieldsToExport.join(','))
        .build(),
    };
    queryClient.fetchQuery({
      queryKey: [QueryKey.EXPORT_PACKAGES],
      async queryFn() {
        const result = await client.get(exportPackagesUrl, {
          params,
        });
        onExportSuccess();
        return result;
      },
    });
  };

  const onExportPackageItemsAsXlsx = ({ fieldsToExport }) => {
    const params = {
      ...filters,
      ...QueryParamsBuilder.new()
        .set('extension', FileExtension.XLSX)
        .set('package__status', PackageStatus.SHIPPED)
        .set('fields', isEmpty(fieldsToExport) ? undefined : fieldsToExport.join(','))
        .build(),
    };
    queryClient.fetchQuery({
      queryKey: [QueryKey.EXPORT_PACKAGE_ITEMS],
      async queryFn() {
        const result = await client.get(exportPackageItemsUrl, { params });
        onExportSuccess();
        return result;
      },
    });
  };

  usePageMeta({ toggleIsBulkUpdatePackagesActive });
  useBreadcrumbs();

  const columns = getColumns({
    t,
    packageStatuses,
    isSuperUser,
    staticFilterNameKey: StaticFiltersNameKey.PACKAGE_SHIPPED,
  });

  const filterFields = useFilterFields({
    t,
    staticFilterNameKey: StaticFiltersNameKey.PACKAGE_SHIPPED,
    shippingCompanies,
    shippingMethodOptions,
    dataSources,
    isSuperUser,
  });

  useEffect(() => {
    mutatePackageStatuses();
  }, []);

  const onExport = ({ exportFormat, exportOption, fieldsToExport }) => {
    if (exportFormat === ExportFormat.XLSX) {
      switch (exportOption) {
        case ExportOption.PACKAGES:
          onExportPackagesAsXlsx({ fieldsToExport });
          break;
        case ExportOption.PACKAGE_ITEMS:
          onExportPackageItemsAsXlsx({ fieldsToExport });
          break;
      }
    }
  };

  const rowSelection = isGenerateShipmentLabelPluginRegistered
    ? {
        onChange: (_, selectedRows) => {
          const params = selectedRows.map((_package) => {
            return {
              first_name: get(_package, 'order.billing_address.first_name'),
              last_name: get(_package, 'order.billing_address.last_name'),
              seller_name: get(_package, 'order.datasource.name'),
              order_number: get(_package, 'order.number'),
              package_number: get(_package, 'number'),
              cargo_company: get(_package, 'shipping_company.name'),
              adress: {
                city_name: get(_package, 'order.shipping_address.city.name'),
                township_name: get(_package, 'order.shipping_address.township.name'),
                district_name: get(_package, 'order.shipping_address.district.name'),
                address_line: get(_package, 'order.shipping_address.line'),
              },
            };
          });
          setParams(params);
        },
      }
    : false;

  return (
    <section>
      <Show when={isBulkUpdatePackagesActive} animateProps={Animations.fade}>
        <BulkUpdatePackages />
      </Show>
      <AkinonFilter filterFields={filterFields} total={total} showFileImport />
      <Divider />
      <AkinonTable
        title={t('packages')}
        columns={columns}
        dataSource={dataSource}
        optionsUrl={listPackagesUrl}
        rowKey={defaultRowKey}
        loading={isDataSourceLoading}
        pagination={pagination}
        setPagination={setPagination}
        rowSelection={rowSelection}
        tableHeaderExtra={
          <PluginRenderer placeholderId="ui-protocol-generate-shipment-label" params={params} />
        }
        exportFormats={[ExportFormat.XLSX]}
        exportOptions={[
          {
            label: t('export_packages'),
            value: ExportOption.PACKAGES,
            loading: Boolean(queryClient.isFetching([QueryKey.EXPORT_PACKAGES])),
          },
          {
            label: t('export_package_items'),
            value: ExportOption.PACKAGE_ITEMS,
            loading: Boolean(queryClient.isFetching([QueryKey.EXPORT_PACKAGE_ITEMS])),
          },
        ]}
        onExport={onExport}
        total={total}
        description={`${total} ${t('results.found')}`}
      />
    </section>
  );
};

export default PackagesWaitingForDelivery;
