import './style.scss';

import AkinonButton from '@components/AkinonButton';
import AkinonForm from '@components/AkinonForm';
import DynamicFormElement from '@components/DynamicFormElement';
import { Form, Modal } from 'antd';
import { merge, omit } from 'lodash';
import React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useOtherCargoCompanyOption } from '../../../hooks/useOtherCargoCompanyOption';
import { CargoInfoModalFormKey } from '../../common';

const ModalWithForm = ({
  onCancel,
  title,
  onFinish,
  formMeta,
  isModalVisible,
  setModalVisible,
  shippingOptionList,
  formResolver,
}) => {
  const { t } = useTranslation('Orders');
  const [cargoOtherOption] = useOtherCargoCompanyOption(shippingOptionList);
  const { control, reset, handleSubmit } = useForm({
    resolver: formResolver,
  });
  const cargoCompany = useWatch({ control, name: CargoInfoModalFormKey.CARGO_COMPANY });
  const meta = formMeta?.();

  const resetModal = () => {
    reset();
    setModalVisible(false);
  };

  if (cargoCompany !== cargoOtherOption?.id) {
    meta.fields = meta.fields.filter((field) => !field.conditional);
  }

  return (
    <Modal
      destroyOnClose
      className="modal-with-form box-primary form-box"
      open={isModalVisible}
      onCancel={() => {
        resetModal();
        onCancel();
      }}
      closable
      centered
      title={title}
      footer={null}
    >
      <AkinonForm
        className="akn-form akn-form-v2"
        preserve={false}
        control={control}
        layout="vertical"
        onFinish={handleSubmit((formValues) => {
          resetModal();
          onFinish(formValues);
        })}
      >
        {meta.fields.map((field) => {
          const widgetProps = merge(omit(field, ['widget', 'widgetProps']), field.widgetProps);
          return (
            <DynamicFormElement
              key={field.name}
              widget={field.widget}
              name={field.key}
              {...widgetProps}
            />
          );
        })}
        <Form.Item wrapperCol={{ span: 10, offset: 14 }}>
          <AkinonButton htmlType="submit" className="modal-with-form__footer-button" type="primary">
            {t('save').toUpperCase()}
          </AkinonButton>
        </Form.Item>
      </AkinonForm>
    </Modal>
  );
};

export default ModalWithForm;
