import { getRequestFiltersParams, getRequestPaginationParams } from '@common/index';
import AkinonFilter from '@components/AkinonFilter';
import { usePageFilters } from '@components/AkinonFilter/hooks/store/usePageFilters';
import AkinonTable from '@components/AkinonTable';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import BulkActionModal from '@components/BulkActionModal';
import { getProductCollectionDetailURL, getProductOffersUrl } from '@constants/apiUrls';
import { defaultRowKey } from '@constants/index';
import { RouteUrls } from '@constants/routeUrls';
import { useIsMobile } from '@hooks/useIsMobile';
import { OfferStatus } from '@pages/ProductsAndCategories/OfferList/common';
import useAppNavigate from '@root/hooks/useAppNavigate';
import useBulkAction from '@root/hooks/useBulkAction';
import { useAddProductToCollectionMutation } from '@services/api/hooks/useAddProductToCollectionMutation';
import { useCollectionItemsQuery } from '@services/api/hooks/useCollectionItemsQuery';
import { useDeleteProductFromCollectionMutation } from '@services/api/hooks/useDeleteProductFromCollectionMutation';
import { usePaginatedProductOffersQuery } from '@services/api/hooks/usePaginatedProductOffersQuery';
import { useProductsQuery } from '@services/api/hooks/usePaginatedProductsQuery';
import { Col, Row } from 'antd';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import reject from 'lodash/reject';
import uniq from 'lodash/uniq';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  getCollectionItemDetail,
  getCollectionProductsColumns,
  getCollectionProductsTableActions,
  getProductListColumns,
  getTableActions,
} from '../common';
import useAddRemoveProductFromCollection from '../hooks/useAddRemoveProductFromCollection';
import useFilterFields from '../hooks/useFilterFields';

const CollectionProducts = ({ collectionId, collectionDetail }) => {
  const { t } = useTranslation(['Marketing', 'ProductsAndCategories']);
  const isMobile = useIsMobile();
  const navigate = useAppNavigate();
  const filters = usePageFilters();
  const [productPoolPagination, setProductPoolPagination] = usePagination();
  const [collectionItemsPagination, setCollectionItemsPagination] = usePagination();

  const filterFields = useFilterFields({ t });

  const { collectionItems, isFetchingCollectionItems, collectionItemsTotal } =
    useCollectionItemsQuery({
      params: {
        collection: collectionId,
        ...getRequestPaginationParams(collectionItemsPagination),
        ...getRequestFiltersParams(filters),
      },
    });
  const collectionOfferIds = uniq(reject(map(collectionItems, 'offer'), isNil));
  const {
    productOffers: collectionProductOffers,
    isFetchingProductOffers: isFetchingCollectionProductOffers,
  } = usePaginatedProductOffersQuery({
    queryOptions: {
      enabled: collectionOfferIds?.length > 0,
    },
    params: {
      id__in: collectionOfferIds?.join(','),
      limit: collectionOfferIds?.length,
    },
  });
  const collectionProductIds = uniq(reject(map(collectionProductOffers, 'product'), isNil));
  const { products: collectionProducts, isFetchingProducts: isCollectionProductsFetching } =
    useProductsQuery({
      queryOptions: {
        enabled: collectionProductIds?.length > 0,
      },
      params: {
        id__in: collectionProductIds?.join(','),
        limit: collectionProductIds?.length,
      },
    });
  const collectionItemWithDetails = getCollectionItemDetail({
    collectionItems,
    collectionProducts,
    collectionProductOffers,
  });

  const { productOffers, isFetchingProductOffers, totalProductOffers } =
    usePaginatedProductOffersQuery({
      pagination: productPoolPagination,
      filters,
      params: {
        not_in_collection: collectionId,
        catalog_item_id__isnull: false,
        status: OfferStatus.approved,
      },
    });
  const productIdsForOffers = uniq(reject(map(productOffers, 'product'), isNil));
  const { products, isFetchingProducts } = useProductsQuery({
    queryOptions: {
      enabled: productIdsForOffers?.length > 0,
    },
    params: {
      id__in: productIdsForOffers?.join(','),
      limit: productIdsForOffers?.length,
    },
  });
  const productOffersWithProductDetails = productOffers?.map((productOffer) => ({
    ...productOffer,
    product: products?.find((product) => product.id === productOffer.product),
  }));

  const { deleteProductFromCollectionAsync, isDeletingProductFromCollection } =
    useDeleteProductFromCollectionMutation();

  const { addProductToCollectionAsync, isAddingProductToCollection } =
    useAddProductToCollectionMutation();

  const { onAddSelectedItems, onDeleteSelectedItems } = useAddRemoveProductFromCollection({
    deleteProductFromCollectionAsync,
    addProductToCollectionAsync,
    collectionId,
  });

  const { onShowModal: onShowAddSelectedItemsModal, ...addSelectedItemsBulkActionProps } =
    useBulkAction({
      bulkActionMutate: onAddSelectedItems,
    });

  const { onShowModal: onShowDeleteItemsModal, ...deleteItemsBulkActionProps } = useBulkAction({
    bulkActionMutate: onDeleteSelectedItems,
  });

  const productsTableColumns = getProductListColumns({
    t,
    addProductToCollectionAsync,
    collectionId,
    isMobile,
  });
  const collectionProductsTableColumns = getCollectionProductsColumns({
    t,
    deleteProductFromCollectionAsync,
    collectionId,
    isMobile,
  });
  const tableActions = getTableActions({
    t,
    onShowAddSelectedItemsModal,
  });
  const collectionProductsTableActions = getCollectionProductsTableActions({
    t,
    onShowDeleteItemsModal,
  });

  const onRow = (record) => ({
    onClick: () => {
      navigate(RouteUrls.productsAndCategories.offerList.approvedDetail, {
        offerId: record.id,
      });
    },
  });

  return (
    <div className="collection-detail">
      <BulkActionModal
        {...addSelectedItemsBulkActionProps}
        modalProps={{
          title: t('Marketing:bulk_add_selected_items'),
          description: t('Marketing:bulk_add_selected_items_desc'),
        }}
        progressModalProps={{ title: t('Marketing:bulk_add_selected_items') }}
      />
      <BulkActionModal
        {...deleteItemsBulkActionProps}
        modalProps={{
          title: t('Marketing:bulk_delete_selected_items'),
          description: t('Marketing:bulk_delete_selected_items_desc'),
        }}
        progressModalProps={{ title: t('Marketing:bulk_delete_selected_items') }}
      />
      <div className="flex gap-4 flex-col">
        <Row>
          <Col span={24}>
            <AkinonFilter
              title={t('filters')}
              filterFields={filterFields}
              total={totalProductOffers}
            />
          </Col>
        </Row>
        <Row span={24} gutter={16}>
          <Col span={12}>
            <AkinonTable
              title={t('ProductsAndCategories:offer.list')}
              columns={productsTableColumns}
              dataSource={productOffersWithProductDetails}
              optionsUrl={getProductOffersUrl}
              loading={
                isFetchingProductOffers ||
                isFetchingProducts ||
                isDeletingProductFromCollection ||
                isAddingProductToCollection
              }
              total={totalProductOffers}
              rowKey={defaultRowKey}
              pagination={productPoolPagination}
              setPagination={setProductPoolPagination}
              tableActions={tableActions}
              rowSelection={true}
              description={`${totalProductOffers || 0} ${t('results.found')}`}
              onRow={onRow}
            />
          </Col>
          <Col span={12}>
            <AkinonTable
              title={collectionDetail?.name || t('collection_products')}
              columns={collectionProductsTableColumns}
              dataSource={collectionItemWithDetails}
              optionsUrl={getProductCollectionDetailURL}
              loading={
                isCollectionProductsFetching ||
                isFetchingCollectionItems ||
                isFetchingCollectionProductOffers ||
                isDeletingProductFromCollection ||
                isAddingProductToCollection
              }
              total={collectionItemsTotal}
              rowKey={defaultRowKey}
              pagination={collectionItemsPagination}
              setPagination={setCollectionItemsPagination}
              tableActions={collectionProductsTableActions}
              rowSelection={true}
              description={`${collectionItemsTotal || 0} ${t('results.found')}`}
              onRow={onRow}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CollectionProducts;
