import { RouteUrls } from '@constants/routeUrls';
import useAppNavigate from '@root/hooks/useAppNavigate';

import { getRequestBodyFromFormValues } from '../common';

export function useOnSubmit({
  optionId,
  updateShippingCompanyOption,
  createShippingCompanyOptions,
  defaultPriority,
  shippingCompanyOptionHttpOptions,
  isSuperUser,
  configId,
  createDataSourceShippingCompanyOptionConfig,
  updateDataSourceShippingCompanyOptionConfig,
}) {
  const navigate = useAppNavigate();

  return (formValues) => {
    if (!formValues.priority) formValues.priority = defaultPriority;

    const requestBody = getRequestBodyFromFormValues({
      formValues,
      shippingCompanyOptionHttpOptions,
    });
    let patch = updateDataSourceShippingCompanyOptionConfig;
    if (isSuperUser && !configId) {
      patch = updateShippingCompanyOption;
    }

    const post = isSuperUser
      ? createShippingCompanyOptions
      : createDataSourceShippingCompanyOptionConfig;

    const patchRequest = (params) =>
      patch(
        { ...params, requestBody },
        { onSuccess: () => navigate(RouteUrls.settings.deliverySettingsShipmentSettings) }
      );

    if (optionId || configId) {
      patchRequest({ optionId, configId });
    } else {
      post({ requestBody });
    }
  };
}
