import AkinonFilter from '@components/AkinonFilter';
import { usePageFilters } from '@components/AkinonFilter/hooks/store/usePageFilters';
import AkinonTable from '@components/AkinonTable';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import { getNotificationsUrl } from '@constants/apiUrls';
import { RouteUrls } from '@constants/routeUrls';
import useAppNavigate from '@hooks/useAppNavigate';
import { useResetTableCurrentPageWhenFiltersChanged } from '@utils/hooks';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { getColumns, StaticFilterKey } from './common';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { useDataSource } from './hooks/useDataSource';
import useFilterFields from './hooks/useFilterFields';
import { usePageMeta } from './hooks/usePageMeta';

const NotificationList = () => {
  const navigate = useAppNavigate();
  const { t } = useTranslation('NotificationManagement');
  const [pagination, setPagination] = usePagination();
  const filters = usePageFilters();
  const { dataSource, total, isDataSourceLoading } = useDataSource({
    pagination,
    filters,
  });

  useBreadcrumbs();
  usePageMeta();
  useResetTableCurrentPageWhenFiltersChanged({ pagination, setPagination, filters });

  const disabledStartDate = useCallback(
    (selectedStartDate) => {
      const endDate = filters[StaticFilterKey.END_DATE];
      if (selectedStartDate && endDate) {
        return selectedStartDate.valueOf() > endDate.valueOf();
      }
    },
    [filters?.[StaticFilterKey.END_DATE]]
  );

  const disabledEndDate = useCallback(
    (selectedEndDate) => {
      const startDate = filters[StaticFilterKey.START_DATE];
      if (selectedEndDate && startDate) {
        return selectedEndDate.valueOf() < startDate.valueOf();
      }
    },
    [filters?.[StaticFilterKey.START_DATE]]
  );

  const filterFields = useFilterFields({
    t,
    disabledStartDate,
    disabledEndDate,
  });
  const columns = getColumns({ t });

  const onRow = (record) => ({
    onClick: () => {
      navigate(RouteUrls.notificationManagement.detail, {
        id: record.id,
      });
    },
  });

  return (
    <section>
      <AkinonFilter title={t('filters')} filterFields={filterFields} total={0} />
      <AkinonTable
        title={`${t('notification_management_title')} (${total})`}
        columns={columns}
        pagination={pagination}
        setPagination={setPagination}
        dataSource={dataSource}
        optionsUrl={getNotificationsUrl}
        loading={isDataSourceLoading}
        description={t('n_results_found', { count: total })}
        total={total}
        rowKey={'id'}
        onRow={onRow}
      />
    </section>
  );
};

export default NotificationList;
