import AkinonFilter from '@components/AkinonFilter';
import { usePageFilters } from '@components/AkinonFilter/hooks/store/usePageFilters';
import AkinonTable from '@components/AkinonTable';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import { getUsersUrl } from '@constants/apiUrls';
import { RouteUrls } from '@constants/routeUrls';
import useAppNavigate from '@root/hooks/useAppNavigate';
import { usePaginatedDataSourcesQuery } from '@services/hooks/datasources/usePaginatedDataSourcesQuery';
import { Divider } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getColumns } from './common';
import { UserManagementFormModal } from './components/UserManagementFormModal';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { useDataSource } from './hooks/useDataSource';
import useFilterFields from './hooks/useFilterFields';
import { usePageMeta } from './hooks/usePageMeta';

const UserManagementListing = () => {
  const { t } = useTranslation('Settings');
  const navigate = useAppNavigate();
  const filters = usePageFilters();
  const [pagination, setPagination] = usePagination();
  const { data: dataSources, isLoading: isDataSourcesLoading } = usePaginatedDataSourcesQuery({
    pagination: {},
    filters: [],
  });
  const filterFields = useFilterFields({ t, dataSources, isDataSourcesLoading });

  const { dataSource, isDataSourceLoading, total } = useDataSource({ filters, pagination });

  const [isAddNewUserModalVisible, setIsAddNewUserModalVisible] = useState(false);

  const columns = getColumns({ t });

  const showAddNewUserModal = () => {
    setIsAddNewUserModalVisible(true);
  };

  const onAddNewUserModalCancel = () => {
    setIsAddNewUserModalVisible(false);
  };

  useBreadcrumbs();
  usePageMeta({ showAddNewUserModal });

  const onRow = (row) => ({
    onClick: () => {
      navigate(RouteUrls.settings.userManagementForm, { id: row.id });
    },
  });

  return (
    <section>
      <UserManagementFormModal
        modalProps={{ open: isAddNewUserModalVisible, onCancel: onAddNewUserModalCancel }}
        filters={filters}
        pagination={pagination}
      />
      <AkinonFilter title={t('filters')} filterFields={filterFields} total={total} />
      <Divider />
      <AkinonTable
        title={t('user_management')}
        columns={columns}
        dataSource={dataSource}
        optionsUrl={getUsersUrl}
        pagination={pagination}
        setPagination={setPagination}
        loading={isDataSourceLoading}
        rowKey="id"
        total={total}
        onRow={onRow}
        description={`${total} ${t('results.found')}`}
      />
    </section>
  );
};

export default UserManagementListing;
