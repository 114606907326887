import { createSelectValidation } from '@common/index';
import { assign, pick } from 'lodash';
import omit from 'lodash/omit';
import { z } from 'zod';

export const FormKey = {
  SHIPPING_COMPANY: 'shipping_company',
  SHIPPING_OPTION: 'shipping_option',
  IS_ACTIVE: 'is_active',
  SLUG: 'slug',
  NAME: 'name',
  PRIORITY: 'priority',
  CALCULATOR: 'calculator',
  CALCULATOR_TYPE: 'calculator.type',
  IS_REQUIRES_MANUAL_ENTRY: 'is_requires_manual_entry',
  RULE: 'rule',
  CONFIG: 'config',
};

export const SchemaKey = {
  ...FormKey,
  CALCULATOR_TYPE: 'type',
};

export const getDynamicCalculatorSchema = (calculator) => {
  const calcParams = omit(calculator?.params, 'slug');

  const result = {};
  for (const key in calcParams) {
    const value = calcParams[key];
    if (value.type === 'decimal') {
      result[key] = z.coerce.number().optional();
    }
  }

  return result;
};

export const getFormSchema = ({
  shippingCompanies,
  shippingOptions,
  shippingCompanyOptionHttpOptions,
  isSuperUser,
  configId,
  t,
}) => {
  const configFormListValidation =
    isSuperUser && Boolean(configId)
      ? null
      : z.array(z.object({ key: z.string(), value: z.string() }));

  const calculators = shippingCompanyOptionHttpOptions?.calculators;

  const calculatorSchemas = assign(
    {},
    ...(calculators?.map((calculator) => getDynamicCalculatorSchema(calculator)) ?? [])
  );

  const schema = z.object({
    [FormKey.SHIPPING_COMPANY]: createSelectValidation(shippingCompanies),
    [FormKey.SHIPPING_OPTION]: createSelectValidation(shippingOptions),
    [FormKey.IS_ACTIVE]: z.coerce.boolean(),
    [FormKey.SLUG]: z.string(),
    [FormKey.NAME]: z.string(),
    [FormKey.PRIORITY]: z.coerce.number(),
    [FormKey.RULE]: z.string(),
    [FormKey.CALCULATOR]: z
      .object({
        [SchemaKey.CALCULATOR_TYPE]: createSelectValidation(calculators, { valueKey: 'slug' }),
        ...calculatorSchemas,
      })
      .superRefine((val, ctx) => {
        const calculator = calculators.find((calc) => calc.slug === val.type);
        const expectedKeys = Object.keys(omit(calculator.params, ['slug', 'prices_per_currency']));
        const emptyKeys = expectedKeys.filter((key) => val[key] === undefined);
        if (emptyKeys.length > 0) {
          emptyKeys.forEach((key) => {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: t('form_validation.required'),
              path: [key],
            });
          });
        }
      })
      .transform((val) => {
        const calculator = calculators.find((calc) => calc.slug === val.type);
        const expectedKeys = Object.keys(omit(calculator.params, ['prices_per_currency']));
        return pick(val, [...expectedKeys, 'type']);
      }),
    [FormKey.IS_REQUIRES_MANUAL_ENTRY]: z.coerce.boolean(),
    [FormKey.CONFIG]: z.array(z.object({ key: z.coerce.string(), value: z.coerce.string() })),
  });

  if (configFormListValidation) schema[FormKey.CONFIG] = configFormListValidation;
  return schema;
};

export const getRequestBodyFromFormValues = ({ formValues, shippingCompanyOptionHttpOptions }) => {
  const configFormValues = formValues[FormKey.CONFIG] || [];
  const selectedCalculator = formValues[FormKey.CALCULATOR].type;
  const selectedCalculatorName = shippingCompanyOptionHttpOptions?.calculators?.find?.(
    (calculator) => calculator.slug === selectedCalculator
  ).name;

  return {
    ...omit(formValues, [FormKey.CONFIG]),
    [FormKey.CALCULATOR]: {
      ...omit(formValues[FormKey.CALCULATOR], 'type'),
      slug: selectedCalculator,
      name: selectedCalculatorName,
    },
    [FormKey.RULE]: JSON.parse(formValues[FormKey.RULE]),
    [FormKey.CONFIG]: configFormValues.reduce((obj, item) => {
      obj[item.key] = item.value;
      return obj;
    }, {}),
  };
};
