import { createSelectOptions } from '@common/index';
import {
  getApprovalStatusOptions,
  getIsNullBooleanAttributeOptions,
  WidgetType,
} from '@constants/commontypes';

import { StaticFilterKey } from '../../common';

const useStaticFilterFields = ({
  t,
  dataSources,
  categoryTreeData,
  isDataSourcesLoading,
  isCategoriesLoading,
  isSuperUser,
}) => {
  const staticFields = [
    isSuperUser && {
      name: StaticFilterKey.DATASOURCE,
      widget: WidgetType.SELECT,
      showSearch: true,
      optionFilterProp: 'label',
      placeholder: t('seller'),
      loading: isDataSourcesLoading,
      options: createSelectOptions(dataSources, {
        labelKey: 'name',
        valueKey: 'id',
      }),
    },
    {
      name: StaticFilterKey.NAME,
      widget: WidgetType.INPUT,
      placeholder: t('product_name'),
    },
    {
      name: StaticFilterKey.SKU,
      widget: WidgetType.INPUT,
      placeholder: t('sku'),
    },
    {
      name: StaticFilterKey.BASE_CODE,
      widget: WidgetType.INPUT,
      placeholder: t('basecode'),
    },
    {
      name: StaticFilterKey.PRODUCT_ISNULL,
      widget: WidgetType.CHECKBOX_GROUP,
      placeholder: t('offer.product.related'),
      options: [{ label: t('offer.product.related'), value: false }],
    },
    {
      name: StaticFilterKey.CATEGORY,
      widget: WidgetType.TREESELECT,
      placeholder: t('category'),
      showSearch: true,
      treeDefaultExpandAll: true,
      virtual: false,
      style: { width: '100%' },
      loading: isCategoriesLoading,
      dropdownStyle: { maxHeight: 400, overflow: 'auto' },
      treeData: categoryTreeData,
      treeNodeFilterProp: 'title',
      allowClear: true,
    },
    {
      name: StaticFilterKey.APPROVAL_STATUS,
      widget: WidgetType.SELECT,
      showSearch: true,
      optionFilterProp: 'label',
      placeholder: t('offer.status'),
      options: getApprovalStatusOptions({ t }),
    },
    {
      name: StaticFilterKey.IMAGE_ISNULL,
      widget: WidgetType.SELECT,
      showSearch: true,
      optionFilterProp: 'label',
      placeholder: t('product_image'),
      options: getIsNullBooleanAttributeOptions({ t }),
    },
  ].filter(Boolean);

  return {
    fields: staticFields,
  };
};

export default useStaticFilterFields;
