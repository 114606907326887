import { createSelectOptions } from '@common/index';
import { UserRole } from '@constants/auth';
import { WidgetType } from '@constants/commontypes';
import { useTranslation } from 'react-i18next';

import { StaticFilterKey } from '../common';

export const useStaticFilterFields = ({ userRole, dataSources, isFetchingDataSources }) => {
  const { t } = useTranslation('Finance');
  const isSuperUser = userRole === UserRole.SUPER_USER;

  const dataSourcesOptions = createSelectOptions(dataSources, {
    labelKey: 'name',
    valueKey: 'id',
  });

  return {
    fields: [
      isSuperUser && {
        name: StaticFilterKey.DATASOURCE,
        widget: WidgetType.SELECT,
        options: dataSourcesOptions,
        loading: isFetchingDataSources,
        placeholder: t('seller'),
      },
      {
        name: StaticFilterKey.ORDER_NUMBER,
        placeholder: t('order_number_1'),
      },
    ].filter(Boolean),
  };
};
