import { useDynamicFilterFields } from '@utils/hooks/useDynamicFilterFields';

import useStaticFilterFields from './useStaticFields';

const useFilterFields = ({
  t,
  attributes,
  dataSources,
  categoryTreeData,
  isDataSourcesLoading,
  isCategoriesLoading,
  isAttributesLoading,
  isSuperUser,
}) => {
  const dynamicFields = useDynamicFilterFields({ attributes, isAttributesLoading });
  const staticFields = useStaticFilterFields({
    t,
    dataSources,
    categoryTreeData,
    isDataSourcesLoading,
    isCategoriesLoading,
    isSuperUser,
  });

  return {
    dynamic: dynamicFields,
    static: staticFields,
  };
};

export default useFilterFields;
