import { formatDateTime } from '@common/dateTime';
import { UserRole } from '@constants/auth';

import { StaticFilterKey } from './hooks/useStaticFilterFields';

export const DataIndex = {
  NUMBER: 'number',
  NAME: 'name',
  SKU: 'sku',
  SUPPLIER: 'supplier',
  ORDER_NUMBER: 'order_number',
  CREATED_AT: 'created_at',
  TOTAL_AMOUNT: 'total_amount',
  DISCOUNT_AMOUNT: 'discount_amount',
  NET_AMOUNT: 'net_amount',
  TAX_RATE: 'tax_rate',
  COMMISSION_PERCENT: 'commission_percent',
  COMMISSION_AMOUNT: 'commission_amount',
};

export function getColumns({ t, reconciliation, userRole }) {
  const isSuperUser = userRole === UserRole.SUPER_USER;

  return [
    {
      dataIndex: DataIndex.NUMBER,
      visibleOnFilter: [StaticFilterKey.RECONCILIATION_NO],
      title: t('reconciliation_number'),
      render: () => reconciliation?.number,
    },
    isSuperUser && {
      dataIndex: DataIndex.NAME,
      visibleOnFilter: [StaticFilterKey.RECONCILIATION_NAME],
      title: t('seller.name'),
      render: () => reconciliation?.name,
    },
    {
      dataIndex: DataIndex.SKU,
      visibleOnFilter: [StaticFilterKey.PRODUCT_CODE],
      title: t('sku'),
    },
    {
      dataIndex: DataIndex.SUPPLIER,
      visibleOnFilter: [StaticFilterKey.SUPPLIER],
      title: t('supplier'),
    },
    {
      dataIndex: DataIndex.ORDER_NUMBER,
      visibleOnFilter: [StaticFilterKey.ORDER_NUMBER],
      title: t('order.number'),
    },
    {
      dataIndex: DataIndex.CREATED_AT,
      visibleOnFilter: [StaticFilterKey.START_DATE_GTE, StaticFilterKey.START_DATE_LTE],
      title: t('order.date'),
      render: (createdAt) => formatDateTime(createdAt),
    },
    {
      dataIndex: DataIndex.TOTAL_AMOUNT,
      title: t('selling.price'),
    },
    {
      dataIndex: DataIndex.DISCOUNT_AMOUNT,
      title: t('discount'),
    },
    {
      dataIndex: DataIndex.NET_AMOUNT,
      title: t('unit_price'),
    },
    {
      dataIndex: DataIndex.TAX_RATE,
      title: t('product_vat'),
      render: (value) => `%${Number(value)}`,
    },
    {
      dataIndex: DataIndex.COMMISSION_PERCENT,
      title: t('commission_rate'),
      render: () => `%${Number(reconciliation?.commission)}`,
    },
    {
      dataIndex: DataIndex.COMMISSION_AMOUNT,
      title: t('commission.amount'),
    },
  ];
}
