import './styles.scss';

import AkinonButton from '@components/AkinonButton';
import AkinonForm from '@components/AkinonForm';
import AkinonFormItem from '@components/AkinonFormItem';
import Box from '@components/utility/box';
import { useBulkImportReconciliationRulesMutation } from '@services/api/hooks/useBulkImportReconciliationRulesMutation';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import DraggerInputWithLabel from '../../../common/DraggerInputWithLabel';
import { FormKey, getFormResolver } from './common';

const BulkUpdateCommission = () => {
  const { t } = useTranslation('Finance');

  const resolver = getFormResolver({ t });

  const { control, handleSubmit } = useForm({
    resolver,
  });

  const { isBulkImportingReconciliationRules, bulkImportReconciliationRules } =
    useBulkImportReconciliationRulesMutation();

  const handleFileUpload = (values) => {
    const formData = new FormData();

    const fileList = values[FormKey.FILE]?.fileList;

    fileList?.forEach((file) => {
      formData.append(FormKey.FILE, file.originFileObj);
    });

    bulkImportReconciliationRules({ formData });
  };

  return (
    <Box
      className="bulk-update-commission__box box-primary form-box"
      title={t('upload_category_commission')}
    >
      <AkinonForm layout="vertical">
        <AkinonFormItem
          name={FormKey.FILE}
          label={t('upload_new_commission')}
          control={control}
          required
        >
          <DraggerInputWithLabel
            accept=".csv,.xlsx,.xls"
            maxCount={1}
            multiple={false}
            beforeUpload={() => false}
          />
        </AkinonFormItem>

        <AkinonButton
          loading={isBulkImportingReconciliationRules}
          type="primary"
          onClick={handleSubmit(handleFileUpload)}
        >
          {t('save')}
        </AkinonButton>
      </AkinonForm>
    </Box>
  );
};

export default BulkUpdateCommission;
