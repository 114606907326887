import CopyButton from '@components/CopyButton';
import ImageTableHead from '@components/ImageTableHead';
import SmallColumnImage from '@components/SmallColumnImage';
import TitleWithHint from '@components/TitleWithHint';
import i18n from '@root/i18n';
import { Tooltip, Typography } from 'antd';
import React from 'react';

export const DataIndex = {
  IMAGE: ['image', 'image'],
  SELLER: {
    NAME: ['datasource', 'name'],
  },
  NAME: 'name',
  BASE_CODE: 'base_code',
  SKU: 'sku',
  CATEGORY: ['category', 'path'],
  PRICES: {
    RETAIL_PRICE: ['prices', '0', 'retail_price'],
    PRICE: ['prices', '0', 'price'],
    CURRENCY: ['prices', '0', 'currency_type'],
  },
  REFERENCE_PRICE: 'buybox_price',
  STOCKS: {
    STOCK: ['stocks', '0', 'amount'],
  },
  PRODUCT: {
    SKU: ['product', 'sku'],
  },
  REASON: ['reason', 'reason'],
  REJECTION: ['rejection', 'explanation'],
  STATUS: 'status',
};

export const StaticFilterKey = {
  DATASOURCE: 'datasource',
  NAME: 'product__name__icontains',
  SKU: 'sku__iexact',
  BASE_CODE: 'base_code__iexact',
  CATEGORY: 'category__path__startswith',
  PRODUCT_ISNULL: 'product__isnull',
  APPROVAL_STATUS: 'approval_status',
  STATUS: 'status',
  IMAGE_ISNULL: 'productimage__isnull',
};

export const ExportKey = {
  SELLER: {
    NAME: 'datasource__name',
  },
  NAME: 'name',
  BASE_CODE: 'base_code',
  SKU: 'sku',
  PRICES: {
    RETAIL_PRICE: 'prices__retail_price',
    PRICE: 'prices__price',
    CURRENCY: 'prices__currency_type',
  },
  REFERENCE_PRICE: 'buybox_price',
  STOCKS: {
    STOCK: 'stocks__amount',
  },
  PRODUCT: {
    SKU: 'product__sku',
  },
  REJECTION: 'rejection__explanation',
  STATUS: 'status',
  IMAGE: 'image',
  CATEGORY: 'category',
  REASON: 'reason',
};

export const getColumns = ({ t, isSuperUser, isMobile }) => {
  return [
    {
      dataIndex: DataIndex.IMAGE,
      visibleOnFilter: [StaticFilterKey.IMAGE_ISNULL],
      title: <ImageTableHead />,
      render: (image) => <SmallColumnImage src={image} />,
      align: 'center',
    },
    isSuperUser && {
      dataIndex: DataIndex.SELLER.NAME,
      visibleOnFilter: [StaticFilterKey.DATASOURCE],
      exportKey: ExportKey.SELLER.NAME,
      exportTitle: t('seller'),
      title: t('seller'),
      className: 'col-type-product-name',
      render: (name) => <Tooltip title={name}>{name}</Tooltip>,
    },
    {
      dataIndex: DataIndex.NAME,
      visibleOnFilter: [StaticFilterKey.NAME],
      exportKey: ExportKey.NAME,
      exportTitle: t('product_name'),
      title: t('product_name'),
      className: 'col-type-product-name',
      render: (name) => <Tooltip title={name}>{name}</Tooltip>,
    },
    {
      dataIndex: DataIndex.BASE_CODE,
      visibleOnFilter: [StaticFilterKey.BASE_CODE],
      exportKey: ExportKey.BASE_CODE,
      exportTitle: 'Base Code',
      title: 'Base Code',
      className: 'uppercase',
      render: (baseCode) => <Tooltip title={baseCode}>{baseCode}</Tooltip>,
    },
    {
      dataIndex: DataIndex.SKU,
      visibleOnFilter: [StaticFilterKey.SKU],
      exportKey: ExportKey.SKU,
      exportTitle: t('sku'),
      title: t('sku'),
      className: 'uppercase',
      render: (sku) => <Tooltip title={sku}>{sku}</Tooltip>,
    },
    {
      dataIndex: DataIndex.CATEGORY,
      visibleOnFilter: [StaticFilterKey.CATEGORY],
      exportKey: ExportKey.CATEGORY,
      title: t('category'),
      render: (category) => <Tooltip title={category}>{category}</Tooltip>,
    },
    {
      dataIndex: DataIndex.PRICES.RETAIL_PRICE,
      exportKey: ExportKey.PRICES.RETAIL_PRICE,
      title: t('psf'),
    },
    {
      dataIndex: DataIndex.PRICES.PRICE,
      exportKey: ExportKey.PRICES.PRICE,
      title: t('price'),
    },
    {
      dataIndex: DataIndex.REFERENCE_PRICE,
      exportKey: ExportKey.REFERENCE_PRICE,
      exportTitle: t('reference_price'),
      title: (
        <TitleWithHint
          text={t('reference_price').toLocaleUpperCase(i18n.language)}
          hint={t('reference_price_desc')}
        />
      ),
    },
    {
      dataIndex: DataIndex.PRICES.CURRENCY,
      exportKey: ExportKey.PRICES.CURRENCY,
      title: t('currency'),
    },
    {
      dataIndex: DataIndex.STOCKS.STOCK,
      exportKey: ExportKey.STOCKS.STOCK,
      title: t('stock'),
      render: (stock) => <Tooltip title={stock}>{stock}</Tooltip>,
    },
    {
      dataIndex: DataIndex.PRODUCT.SKU,
      exportKey: ExportKey.PRODUCT.SKU,
      title: t('sku.related'),
      className: 'uppercase',
      render: (data) =>
        data ? <CopyButton tooltipVisible={true} text={data} label={data} isColumn /> : null,
    },
    {
      dataIndex: DataIndex.REASON,
      exportKey: ExportKey.REASON,
      title: t('rejection.reason'),
      render: (data) => <Tooltip title={data}>{data}</Tooltip>,
    },
    {
      dataIndex: DataIndex.REJECTION,
      exportKey: ExportKey.REJECTION,
      title: t('rejection.reason.explanation'),
      render: (data) => {
        return <Tooltip title={data}>{data}</Tooltip>;
      },
    },
    {
      dataIndex: DataIndex.STATUS,
      visibleOnFilter: [StaticFilterKey.STATUS],
      exportKey: ExportKey.STATUS,
      title: t('offer.status'),
      fixed: isMobile ? false : 'right',
      className: 'offer-list__status_column',
      render: (_) => {
        return <div className="offer-status offer-rejected">{t('offer.rejected')}</div>;
      },
    },
  ];
};

export const getTourSteps = ({ t }) => {
  return [
    {
      selector: '.standard-akinon-table-header__title',
      content: (
        <>
          <Typography.Title level={4}>{t('rejected_offers_guide_step_1_title')}</Typography.Title>
          <Typography.Text>{t('rejected_offers_guide_step_1_desc')}</Typography.Text>
        </>
      ),
    },
    {
      selector: '.filter-options',
      content: (
        <>
          <Typography.Title level={4}>{t('rejected_offers_guide_step_2_title')}</Typography.Title>
          <Typography.Text>{t('rejected_offers_guide_step_2_desc')}</Typography.Text>
        </>
      ),
    },
    {
      selector: '.standard-akinon-table-header__export-option',
      content: (
        <>
          <Typography.Title level={4}>{t('rejected_offers_guide_step_3_title')}</Typography.Title>
          <Typography.Text>{t('rejected_offers_guide_step_3_desc')}</Typography.Text>
        </>
      ),
    },
  ];
};
