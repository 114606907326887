import { useProductImagesQuery } from '@services/api/hooks/usePaginatedProductImagesQuery';
import { usePaginatedRestrictedProductsQuery } from '@services/api/hooks/usePaginatedProductRestrictionsQuery';
import { usePaginatedProductsQuery } from '@services/api/hooks/usePaginatedProductsQuery';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';

export const useDataSource = ({
  filters,
  productsPagination,
  restrictedProductsPagination,
  datasourceId,
}) => {
  const { products, isFetchingProducts, totalProducts, refetchProducts } =
    usePaginatedProductsQuery({
      pagination: productsPagination,
      filters,
      params: {
        has_no_restriction_for: datasourceId,
      },
    });

  const {
    products: restrictedProducts,
    isFetchingProducts: isFetchingRestrictedProducts,
    totalProducts: totalRestrictedProducts,
    refetchProducts: refetchRestrictedProducts,
  } = usePaginatedProductsQuery({
    filters,
    pagination: restrictedProductsPagination,
    params: {
      has_restriction_for: datasourceId,
    },
  });

  const productIds = uniq(products?.map?.((product) => product?.id));
  const restrictedProductIds = uniq(restrictedProducts?.map?.((product) => product?.id));
  const allIds = [...productIds, ...restrictedProductIds];

  const {
    restrictedProductDetails,
    isFetchingRestrictedProductDetails,
    refetchRestrictedProductDetails,
  } = usePaginatedRestrictedProductsQuery({
    params: {
      product__in: restrictedProductIds?.join(','),
      datasource: datasourceId,
      // limit: restrictedProductIds?.length,
      limit: 9999,
    },
    queryOptions: {
      enabled: !isEmpty(restrictedProductIds),
    },
  });

  const { productImages, isFetchingProductImages } = useProductImagesQuery({
    params: {
      limit: allIds?.length,
      parent__in: allIds?.join(','),
      order: 0,
    },
    queryOptions: {
      enabled: !isFetchingProducts && !isFetchingRestrictedProducts && !isEmpty(productIds),
    },
  });

  const productsDataSource = products?.map((product) => ({
    ...product,
    image: productImages?.find((productImage) => productImage.parent === product.id)?.image,
  }));

  const restrictedProductsDataSource = restrictedProducts?.map((product) => {
    const restrictedProductDetail = restrictedProductDetails?.find(
      (detail) => detail.product === product.id && detail.datasource === datasourceId
    );
    return {
      ...product,
      ...restrictedProductDetail,
      image: productImages?.find((productImage) => productImage.parent === product.id)?.image,
    };
  });

  return {
    productsDataSource,
    isFetchingProducts: isFetchingProducts || isFetchingProductImages,
    isFetchingRestrictedProducts:
      isFetchingRestrictedProducts || isFetchingRestrictedProductDetails,
    totalProducts,
    restrictedProductsDataSource: restrictedProductsDataSource,
    isRestrictedProductsDataSourceLoading: isFetchingProductImages,
    totalRestrictedProducts,
    refetchRestrictedProducts: () => {
      refetchRestrictedProducts();
      refetchRestrictedProductDetails();
    },
    refetchProducts,
  };
};
