import { WidgetType } from '@constants/commontypes';

import { StaticFilterKey } from './common';

export default function useStaticFilterFields({ t }) {
  return {
    fields: [
      {
        name: StaticFilterKey.PRODUCT_NAME,
        widget: WidgetType.INPUT,
        placeholder: t('product_name'),
      },
      {
        name: StaticFilterKey.PRODUCT_BASE_CODE,
        widget: WidgetType.INPUT,
        placeholder: t('base.code'),
      },
      {
        name: StaticFilterKey.OFFER_SKU,
        widget: WidgetType.INPUT,
        placeholder: t('offer_code'),
      },
      {
        name: StaticFilterKey.PRODUCT_SKU,
        widget: WidgetType.INPUT,
        placeholder: t('sku'),
      },
      {
        name: StaticFilterKey.IS_ACTIVE,
        widget: WidgetType.SELECT,
        placeholder: t('status'),
        options: [
          { label: t('active'), value: true },
          { label: t('passive'), value: false },
        ],
        showSearch: true,
        optionFilterProp: 'label',
      },
    ],
  };
}
