import { getIsNullBooleanAttributeOptions, WidgetType } from '@constants/commontypes';

import { StaticFilterKey } from './common';

export default function useStaticFilterFields({ categoryTreeData, isCategoriesLoading, t }) {
  return {
    fields: [
      {
        name: StaticFilterKey.NAME,
        widget: WidgetType.INPUT,
        placeholder: t('product_name'),
      },
      {
        name: StaticFilterKey.CATEGORY_PATH,
        placeholder: t('category'),
        widget: WidgetType.TREESELECT,
        showSearch: true,
        treeDefaultExpandAll: true,
        virtual: false,
        style: { width: '100%' },
        dropdownStyle: { maxHeight: 400, overflow: 'auto' },
        treeData: categoryTreeData,
        loading: isCategoriesLoading,
        treeNodeFilterProp: 'title',
        allowClear: true,
      },
      {
        name: StaticFilterKey.BASE_CODE,
        widget: WidgetType.INPUT,
        placeholder: t('base.code'),
      },
      {
        name: StaticFilterKey.SKU,
        widget: WidgetType.INPUT,
        placeholder: t('sku'),
      },
      {
        name: StaticFilterKey.IMAGE_ISNULL,
        widget: WidgetType.SELECT,
        placeholder: t('product_image'),
        options: getIsNullBooleanAttributeOptions({ t }),
      },
    ],
  };
}
