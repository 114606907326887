import { useDynamicFilterFields } from '@utils/hooks/useDynamicFilterFields';

import { getStaticFilters } from '../common';

const useFilterFields = ({
  t,
  attributes,
  isAttributesLoading,
  isSuperUser,
  dataSources,
  categoryTreeData,
  isCategoriesLoading,
  isDataSourcesLoading,
}) => {
  const dynamicFields = useDynamicFilterFields({ attributes, isAttributesLoading });
  const staticFilters = getStaticFilters({
    t,
    dataSources,
    categoryTreeData,
    isDataSourcesLoading,
    isCategoriesLoading,
    isSuperUser,
  });

  return {
    dynamic: dynamicFields,
    static: staticFilters,
  };
};

export default useFilterFields;
