import { getStaticFilters } from '../common';

const useFilterFields = ({ t, products }) => {
  const staticFields = getStaticFilters({ t, products });

  return {
    dynamic: { fields: [], isLoading: false },
    static: staticFields,
  };
};

export default useFilterFields;
