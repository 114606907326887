import './style.scss';

import AkinonButton from '@components/AkinonButton';
import AkinonForm from '@components/AkinonForm';
import AkinonInput from '@components/AkinonInput';
import { openNotification } from '@components/akinonNotification';
import AkinonSpin from '@components/AkinonSpin';
import Box from '@components/utility/box/index';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMeMutation } from '@services/api/hooks/useMeMutation';
import { useMeQuery } from '@services/api/hooks/useMeQuery';
import { Form } from 'antd';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormKey, getFormSchema } from './common';

const UserProfile = () => {
  const { t } = useTranslation('Profile');
  const { me, isMeFetching } = useMeQuery({
    onSuccess: (meData) => {
      reset(meData);
    },
  });
  const { patchMe } = useMeMutation({
    mutationOptions: {
      onSuccess: () => {
        openNotification({
          message: t('profile_information_saved'),
          type: 'success',
          t,
        });
      },
      onError: (error) => {
        openNotification({
          message: t('profile_information_save_failed'),
          description: error?.response?.data?.detail ?? t('error_occured_while_saving'),
          type: 'error',
          t,
        });
      },
    },
  });
  const schema = getFormSchema();
  const { control, handleSubmit, reset } = useForm({
    resolver: zodResolver(schema),
    defaultValues: me,
  });

  useEffect(() => {
    if (me) {
      reset(me);
    }
  }, [me, reset]);

  const onSubmit = (formValues) => {
    patchMe({ requestBody: formValues });
  };

  return (
    <section className="user-profile">
      <Box className="box-primary form-box" title={t('modify_user')}>
        <AkinonSpin spinning={isMeFetching}>
          <AkinonForm onFinish={handleSubmit(onSubmit)} layout="vertical">
            <AkinonInput
              formItemProps={{
                name: FormKey.FIRST_NAME,
                label: t('first_name'),
                control,
              }}
              placeholder={t('first_name')}
            />
            <AkinonInput
              formItemProps={{
                name: FormKey.LAST_NAME,
                label: t('last_name'),
                control,
              }}
              placeholder={t('last_name')}
            />
            <AkinonInput
              formItemProps={{
                name: FormKey.EMAIL,
                label: t('email'),
                control,
                required: true,
              }}
              placeholder={t('email')}
            />
            <Form.Item wrapperCol={{ span: 24 }}>
              <AkinonButton
                disabled={isMeFetching}
                htmlType="submit"
                type="primary"
                className="user-profile__submit-button"
                name="user-profile-submit"
              >
                {t('submit')}
              </AkinonButton>
            </Form.Item>
          </AkinonForm>
        </AkinonSpin>
      </Box>
    </section>
  );
};

export default UserProfile;
