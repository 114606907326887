import { useDynamicFilterFields } from '@utils/hooks/useDynamicFilterFields';

import { useStaticFilterFields } from './useStaticFilterFields';

const useFilterFields = ({
  attributes,
  isAttributesLoading,
  product,
  categories,
  isCategoriesLoading,
}) => {
  const dynamicFields = useDynamicFilterFields({ attributes, isAttributesLoading });
  const staticFields = useStaticFilterFields({
    product,
    categories,
    isCategoriesLoading,
  });

  return {
    dynamic: dynamicFields,
    static: staticFields,
  };
};

export default useFilterFields;
