import AkinonCheckbox, { AkinonCheckboxGroup } from '@components/AkinonCheckbox';
import AkinonDatePicker from '@components/AkinonDatePicker';
import AkinonDragger from '@components/AkinonDragger';
import AkinonInput, {
  AkinonInputNumber,
  AkinonPasswordInput,
  AkinonTextArea,
} from '@components/AkinonInput';
import { AkinonRadio } from '@components/AkinonRadio';
import AkinonSelect from '@components/AkinonSelect';
import AkinonSwitch from '@components/AkinonSwitch';
import AkinonTreeSelect from '@components/AkinonTreeSelect';
import AkinonFieldList from '@components/FieldList';
import { AkinonQuillEditor } from '@components/QuillEditor';
import { WidgetType } from '@constants/commontypes';
import { ApiConfigType } from '@pages/ProductsAndCategories/ProductForm/common';
import omit from 'lodash/omit';
import React from 'react';

const widgetNameToComponent = {
  [WidgetType.SWITCH]: AkinonSwitch,
  [WidgetType.BOOLEAN]: AkinonSwitch,
  [WidgetType.SELECT]: AkinonSelect,
  [WidgetType.INPUT]: AkinonInput,
  [WidgetType.TEXTAREA]: AkinonTextArea,
  [WidgetType.NUMBER]: AkinonInputNumber,
  [WidgetType.DECIMAL]: AkinonInputNumber,
  [WidgetType.PERCENTAGE]: AkinonInputNumber,
  [WidgetType.PASSWORD]: AkinonPasswordInput,
  [WidgetType.DATE_PICKER]: AkinonDatePicker,
  [WidgetType.CHECKBOX]: AkinonCheckbox,
  [WidgetType.RADIO]: AkinonRadio,
  [WidgetType.EDITOR]: AkinonQuillEditor,
  [WidgetType.FILE]: AkinonDragger,
  [WidgetType.CHECKBOX_GROUP]: AkinonCheckboxGroup,
  [ApiConfigType.DROPDOWN]: AkinonSelect,
  [WidgetType.CUSTOM]: ({ Component, ...props }) => <Component {...props} />,
  [WidgetType.NESTED]: ({ fields }) => {
    return fields.map((field) => <DynamicFormElement key={field.formItemProps.name} {...field} />);
  },
  [WidgetType.LIST]: AkinonFieldList,
  [WidgetType.TREESELECT]: AkinonTreeSelect,
};

const getDefaultProps = ({ widget }) => {
  const defaultProps = {
    [WidgetType.DECIMAL]: {
      step: '0.1',
    },
    [WidgetType.PERCENTAGE]: {
      step: '1',
      min: 0,
      max: 999,
      formatter: (value) => `${value}%`,
    },
  };
  return defaultProps[widget] ?? {};
};

/**
 * @param {Object} props
 * @param {WidgetType} [props.widget]
 * @param {Object} [props.defaultProps]
 * @param {AkinonFormItemProps} [props.formItemProps]
 * @returns
 */
function DynamicFormElement({
  widget = WidgetType.INPUT,
  defaultProps = getDefaultProps({ widget }),
  propsToOmit = [],
  ...props
}) {
  const Component = widgetNameToComponent[widget] ?? AkinonInput;
  const finalProps = omit({ ...defaultProps, ...props }, propsToOmit);

  return React.createElement(Component, finalProps);
}

export default DynamicFormElement;
