import { getActivePassiveBooleanOptions, WidgetType } from '@constants/commontypes';

import { StaticFilterKey } from '../common';

const useFilterFields = ({ t, dataSources, isDataSourcesLoading }) => {
  return {
    static: {
      fields: [
        {
          name: StaticFilterKey.FIRST_NAME,
          placeholder: t('first_name'),
        },
        {
          name: StaticFilterKey.LAST_NAME,
          placeholder: t('last_name'),
        },
        {
          name: StaticFilterKey.EMAIL,
          visibleOnFilter: [StaticFilterKey.EMAIL],
          placeholder: t('email'),
        },
        {
          name: StaticFilterKey.IS_ACTIVE,
          placeholder: t('status'),
          widget: WidgetType.SELECT,
          options: getActivePassiveBooleanOptions({ t }),
          showSearch: true,
          optionFilterProp: 'label',
        },
        {
          name: StaticFilterKey.DATASOURCE,
          placeholder: t('seller'),
          widget: WidgetType.SELECT,
          options: dataSources?.map((item) => ({ label: item.name, value: item.id })) || [],
          loading: isDataSourcesLoading,
          showSearch: true,
          optionFilterProp: 'label',
        },
        {
          name: StaticFilterKey.SOURCE_IN,
          widget: WidgetType.CHECKBOX_GROUP,
          placeholder: t('show_integration_users'),
          options: [
            { label: t('show_integration_users'), name: StaticFilterKey.SOURCE_IN, value: false },
          ],
        },
      ],
    },
    dynamic: { fields: [], isLoading: false },
  };
};

export default useFilterFields;
