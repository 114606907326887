import { RouteUrls } from './routeUrls';

export const ACCESS_TOKEN_STORAGE_KEY = 'access_token';
export const REFRESH_TOKEN_STORAGE_KEY = 'refresh_token';
export const REQUEST_AUTHORIZATION_HEADER = 'Bearer';

export const HttpStatus = {
  PERMANENT_REDIRECT: 301,
  TEMPORARY_REDIRECT: 302,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
};

export const UserRole = {
  SUPER_USER: 'super_user',
  SELLER: 'seller',
  GUEST: 'guest',
};

export const urlUserRoleMap = {
  [RouteUrls.productsAndCategories.bulkActions.landing]: UserRole.SELLER,
  [RouteUrls.settings.sellerUserManagementSettings]: UserRole.SELLER,
  [RouteUrls.settings.dynamicSettings]: UserRole.SUPER_USER,
  [RouteUrls.settings.userManagement]: UserRole.SUPER_USER,
  [RouteUrls.settings.sellerOfferRejectReasons]: UserRole.SUPER_USER,
  [RouteUrls.settings.attributeSetAndCategoryMapping]: UserRole.SUPER_USER,
  [RouteUrls.settings.sellerStateTransitions]: UserRole.SUPER_USER,
  [RouteUrls.settings.buyboxRules]: UserRole.SUPER_USER,
  [RouteUrls.productsAndCategories.products.productForm]: UserRole.SELLER,
  [RouteUrls.support.supportTicketForm]: UserRole.SELLER,
  [RouteUrls.reporting.sellerPerformance]: UserRole.SUPER_USER,
  [RouteUrls.settings.accessTokens]: UserRole.SELLER,
  [RouteUrls.settings.integrationErrors]: UserRole.SUPER_USER,
  [RouteUrls.settings.ssoLoginSettingsUrl]: UserRole.SUPER_USER,
  [RouteUrls.settings.ssoLoginSettingsFormUrl]: UserRole.SUPER_USER,
  [RouteUrls.finance.sellerManagement.sellerDetail]: UserRole.SUPER_USER,
  [RouteUrls.notificationManagement.landing]: UserRole.SUPER_USER,
  [RouteUrls.finance.agreements]: UserRole.SUPER_USER,
  [RouteUrls.stockLocations.landing]: UserRole.SELLER,
  [RouteUrls.stockLocations.create]: UserRole.SELLER,
  [RouteUrls.stockLocations.detail]: UserRole.SELLER,
};

export const liveVersionUrlPredicates = [
  (url) =>
    url.startsWith(RouteUrls.productsAndCategories.landing) &&
    url !== RouteUrls.productsAndCategories.productRevisions.landing,
  (url) => url.startsWith(RouteUrls.order.landing),
  (url) => url.startsWith(RouteUrls.finance.landing) && url !== RouteUrls.finance.dashboard,
  (url) =>
    url.startsWith(RouteUrls.settings.landing) &&
    url !== RouteUrls.settings.sellerPerformanceSettings &&
    url !== RouteUrls.settings.sellerStateTransitions,
  (url) => url === RouteUrls.profile.languageSettings,
  (url) => url === RouteUrls.profile.activeUser,
  (url) => url === RouteUrls.download.landing,
  (url) => url.startsWith(RouteUrls.messages.landing) && url !== RouteUrls.messages.supportTickets,
  (url) => url === RouteUrls.dashboard.landing,
  (url) => url.startsWith(RouteUrls.support.landing),
  (url) => url === RouteUrls.settings.accessTokens,
  (url) => url === RouteUrls.sellerStoreManagement.landing,
  (url) => url === RouteUrls.importExportOperations.landing,
  (url) => url === RouteUrls.productsAndCategories.productRevisions.landing,
  (url) => url.startsWith(RouteUrls.marketing.landing),
  (url) => url.startsWith(RouteUrls.marketing.campaigns.landing),
  (url) => url.startsWith(RouteUrls.notificationManagement.landing),
  (url) => url.startsWith(RouteUrls.appShell.landing),
];

export const externalUrls = [RouteUrls.download.export];
