import { useDynamicColumnsAttributeValues } from '@pages/ProductsAndCategories/hooks/useDynamicColumnsAttributeValues';
import { useCategoriesQuery } from '@services/api/hooks/useCategoriesQuery';
import { useProductImagesQuery } from '@services/api/hooks/usePaginatedProductImagesQuery';
import {
  usePaginatedProductsQuery,
  usePaginatedProductsWithFileQuery,
} from '@services/api/hooks/usePaginatedProductsQuery';
import entries from 'lodash/entries';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import reject from 'lodash/reject';
import uniq from 'lodash/uniq';

export function useDataSource({ dynamicFormMetaFields, pagination, filters }) {
  const { categories } = useCategoriesQuery();
  const hasFileFilter = Object.values(filters ?? {}).some((filter) => filter?.originFileObj);
  const response = usePaginatedProductsQuery({
    pagination,
    filters,
    params: {
      is_active: true,
    },
    queryOptions: {
      enabled: !hasFileFilter,
    },
  });
  const _response = usePaginatedProductsWithFileQuery({
    pagination,
    filters,
    params: {
      is_active: true,
    },
    queryOptions: {
      enabled: hasFileFilter,
    },
  });

  const { products, totalProducts, isFetchingProducts, refetchProducts } = hasFileFilter
    ? _response
    : response;

  const productIds = reject(uniq(map(products, 'id')), isNil);
  const { productImages, isFetchingProductImages } = useProductImagesQuery({
    params: {
      limit: productIds.length,
      parent__in: productIds?.join(','),
      order: 0,
    },
    queryOptions: { enabled: !isEmpty(productIds) },
  });

  const { attributeValues, isFetchingAttributeValues } = useDynamicColumnsAttributeValues({
    dynamicFormMetaFields,
  });

  const isDataSourceLoading =
    isFetchingProducts || isFetchingProductImages || isFetchingAttributeValues;

  const dataSource = products?.map((product) => {
    const attributes = reduce(
      entries(product.attributes || {}),
      (acc, [key, value]) => {
        const attributeOptions = attributeValues?.[key] ?? [];
        const valueLabel = get(find(attributeOptions, { value }), 'label', value);
        return {
          ...acc,
          [key]: valueLabel,
        };
      },
      {}
    );

    return {
      ...product,
      attributes,
      image: productImages?.find(({ parent: imageProductId }) => imageProductId === product.id)
        ?.image,
      category: categories?.find(({ id: categoryId }) => categoryId === product.category)?.path,
    };
  });

  return {
    dataSource,
    total: totalProducts,
    isDataSourceLoading: isDataSourceLoading,
    refetchProducts,
  };
}
