import { openNotification } from '@components/akinonNotification';
import { rejectPreOfferURL, submitPreOfferURL } from '@constants/apiUrls';
import { client } from '@services/api/client';

import { PreOfferStatus } from '../../common';
import { FormKey } from '../../OfferDetail/components/RejectModal/common';

const useRejectOffers = ({ t, refetchOffers, resetFields }) => {
  const rejectSelectedItems = async ({
    selectedRows,
    [FormKey.REJECTION_REASON]: rejectionReason,
    [FormKey.REJECTION_EXPLANATION]: rejectionExplanation,
    mutationOptions,
  }) => {
    const { onSuccess, onError } = mutationOptions;

    const approvalRequiredSelectedRows = selectedRows.filter(
      (selectedRow) => selectedRow.approval_status === PreOfferStatus.APPROVAL_REQUIRED
    );

    const approvePreOfferPromises = approvalRequiredSelectedRows.map((row) => {
      return () =>
        client.post(submitPreOfferURL, {}, { urlParams: { id: row.id } }).catch(() => {});
    });

    const rejectionPromises = selectedRows.map((row) => {
      return () =>
        client
          .post(
            rejectPreOfferURL,
            {
              reason: rejectionReason,
              explanation: rejectionExplanation,
            },
            { urlParams: { id: row.id } }
          )
          .then((result) => {
            if (selectedRows.length === 1) {
              openNotification({
                message: t('success'),
                description: t('transaction_success'),
                type: 'success',
              });
            }
            onSuccess(result);
          })
          .catch((error) => {
            if (selectedRows.length === 1) {
              openNotification({
                message: error?.message ?? t('error'),
                description:
                  error.response?.data?.detail ??
                  error.response?.data?.non_field_errors ??
                  error.message,
                type: 'error',
              });
            }
            onError(error);
          });
    });

    await Promise.allSettled(approvePreOfferPromises.map((f) => f()));
    await Promise.allSettled(rejectionPromises.map((f) => f()));

    refetchOffers();
    resetFields?.();
  };

  return {
    rejectSelectedItems,
  };
};

export default useRejectOffers;
