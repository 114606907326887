import useStaticFilterFields from './useStaticFilterFields';

const useFilterFields = ({ t }) => {
  const staticFilterFields = useStaticFilterFields({ t });

  return {
    dynamic: { fields: [], isLoading: false },
    static: staticFilterFields,
  };
};

export default useFilterFields;
