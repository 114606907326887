import ImageTableHead from '@components/ImageTableHead';
import SmallColumnImage from '@components/SmallColumnImage';
import {
  getIsNullBooleanAttributeOptions,
  getStatusOptions,
  WidgetType,
} from '@constants/commontypes';
import { Tooltip, Typography } from 'antd';
import clsx from 'clsx';
import React from 'react';

export const StaticFilterKey = {
  DATASOURCE: 'datasource',
  NAME: 'product__name__icontains',
  OFFER_CODE: 'sku',
  SKU: 'product__sku__iexact',
  BASE_CODE: 'product__base_code__iexact',
  CATEGORY: 'product__category__path__startswith',
  PRODUCT_ISNULL: 'product__isnull',
  STATUS: 'status',
  IMAGE_ISNULL: 'productimage__isnull',
};

export const OfferStatus = {
  PASSIVE: 0,
  ACTIVE: 1,
};

export const DataIndex = {
  IMAGE: ['image', 'image'],
  DATASOURCE_NAME: ['datasource', 'name'],
  PRODUCT_NAME: ['product', 'name'],
  PRODUCT_BASE_CODE: ['product', 'base_code'],
  PRODUCT_SKU: ['product', 'sku'],
  OFFER_CODE: 'offer_code',
  CATEGORY_PATH: ['category', 'path'],
  STATUS: 'status',
};

export const getColumns = ({ t, isMobile }) => {
  return [
    {
      dataIndex: DataIndex.IMAGE,
      visibleOnFilter: [StaticFilterKey.IMAGE_ISNULL],
      title: <ImageTableHead />,
      render: (image) => <SmallColumnImage src={image} />,
      align: 'center',
    },
    {
      dataIndex: DataIndex.DATASOURCE_NAME,
      visibleOnFilter: [StaticFilterKey.DATASOURCE],
      exportKey: 'datasource__name',
      title: t('seller'),
      className: 'col-type-product-name',
      render: (name) => <Tooltip title={name}>{name}</Tooltip>,
    },
    {
      dataIndex: DataIndex.PRODUCT_NAME,
      visibleOnFilter: [StaticFilterKey.NAME],
      exportKey: 'product__name',
      title: t('product_name'),
      className: 'col-type-product-name',
      render: (name) => <Tooltip title={name}>{name}</Tooltip>,
    },
    {
      dataIndex: DataIndex.PRODUCT_BASE_CODE,
      visibleOnFilter: [StaticFilterKey.BASE_CODE],
      exportKey: 'product__base_code',
      title: 'Base Code',
      className: 'uppercase',
      render: (baseCode) => <Tooltip title={baseCode}>{baseCode}</Tooltip>,
    },
    {
      dataIndex: DataIndex.OFFER_CODE,
      visibleOnFilter: [StaticFilterKey.OFFER_CODE],
      exportKey: 'offer_code',
      title: t('ProductsAndCategories:offer_code'),
      className: 'uppercase',
      render: (offerCode) => <Tooltip title={offerCode}>{offerCode}</Tooltip>,
    },
    {
      dataIndex: DataIndex.PRODUCT_SKU,
      visibleOnFilter: [StaticFilterKey.SKU],
      exportKey: 'product__sku',
      title: t('sku'),
      className: 'uppercase',
      render: (sku) => <Tooltip title={sku}>{sku}</Tooltip>,
    },
    {
      dataIndex: DataIndex.CATEGORY_PATH,
      visibleOnFilter: [StaticFilterKey.CATEGORY],
      exportKey: 'category',
      title: t('category'),
      render: (category) => <Tooltip title={category}>{category}</Tooltip>,
    },
    {
      dataIndex: DataIndex.STATUS,
      visibleOnFilter: [StaticFilterKey.STATUS],
      exportKey: 'status',
      exportTitle: t('ProductsAndCategories:offer.status'),
      fixed: isMobile ? false : 'right',
      title: t('ProductsAndCategories:offer.status'),
      render: (status) => {
        return (
          <div
            className={clsx('offer-status', {
              'offer-approved': status,
              'offer-rejected': !status,
            })}
          >
            {status ? t('active') : t('passive')}
          </div>
        );
      },
    },
  ];
};

export const getStaticFilters = ({
  t,
  dataSources,
  categoryTreeData,
  isDataSourcesLoading,
  isCategoriesLoading,
  isSuperUser,
}) => ({
  fields: [
    isSuperUser && {
      name: StaticFilterKey.DATASOURCE,
      widget: WidgetType.SELECT,
      showSearch: true,
      optionFilterProp: 'label',
      placeholder: t('seller'),
      loading: isDataSourcesLoading,
      options: dataSources?.map(({ id, name }) => ({ label: name, key: id, value: id })) || [],
    },
    {
      name: StaticFilterKey.NAME,
      widget: WidgetType.INPUT,
      placeholder: t('product_name'),
    },
    {
      name: StaticFilterKey.OFFER_CODE,
      widget: WidgetType.INPUT,
      placeholder: t('offer_code'),
    },
    {
      name: StaticFilterKey.SKU,
      widget: WidgetType.INPUT,
      placeholder: t('sku'),
    },
    {
      name: StaticFilterKey.BASE_CODE,
      widget: WidgetType.INPUT,
      placeholder: t('basecode'),
    },
    {
      name: StaticFilterKey.CATEGORY,
      widget: WidgetType.TREESELECT,
      placeholder: t('category'),
      showSearch: true,
      treeDefaultExpandAll: true,
      virtual: false,
      style: { width: '100%' },
      loading: isCategoriesLoading,
      dropdownStyle: { maxHeight: 400, overflow: 'auto' },
      treeData: categoryTreeData,
      treeNodeFilterProp: 'title',
      allowClear: true,
    },
    {
      name: StaticFilterKey.STATUS,
      widget: WidgetType.SELECT,
      showSearch: true,
      optionFilterProp: 'label',
      placeholder: t('offer.status'),
      options: getStatusOptions({ t }),
    },
    {
      name: StaticFilterKey.IMAGE_ISNULL,
      widget: WidgetType.SELECT,
      showSearch: true,
      optionFilterProp: 'label',
      placeholder: t('product_image'),
      options: getIsNullBooleanAttributeOptions({ t }),
    },
  ].filter(Boolean),
});

export const getTableActions = ({
  t,
  onShowMakeOfferActiveModal,
  onShowMakeOfferPassiveModal,
  onShowMakeFilteredOffersActiveModal,
  onShowMakeFilteredOffersPassiveModal,
  hasAppliedFilters,
  selectedRowKeys,
}) => {
  const hasSelectedRows = selectedRowKeys.length > 0;
  return [
    hasSelectedRows && {
      label: t('make_selected_offers_passive', { count: selectedRowKeys.length }),
      actionHandler(selectedRows) {
        onShowMakeOfferPassiveModal(selectedRows);
      },
      isSelectionRequired: true,
    },
    hasSelectedRows && {
      label: t('make_selected_offers_active', { count: selectedRowKeys.length }),
      actionHandler(selectedRows) {
        onShowMakeOfferActiveModal(selectedRows);
      },
      isSelectionRequired: true,
    },
    !hasSelectedRows &&
      !hasAppliedFilters && {
        label: t('make_all_offers_active'),
        actionHandler(selectedRows) {
          onShowMakeFilteredOffersActiveModal(selectedRows);
        },
        isSelectionRequired: false,
      },
    !hasSelectedRows &&
      !hasAppliedFilters && {
        label: t('make_all_offers_passive'),
        actionHandler(selectedRows) {
          onShowMakeFilteredOffersPassiveModal(selectedRows);
        },
        isSelectionRequired: false,
      },
    hasAppliedFilters &&
      !hasSelectedRows && {
        label: hasAppliedFilters ? t('make_filtered_offers_active') : t('make_all_offers_active'),
        actionHandler(selectedRows) {
          onShowMakeFilteredOffersActiveModal(selectedRows);
        },
        isSelectionRequired: false,
      },
    hasAppliedFilters &&
      !hasSelectedRows && {
        label: hasAppliedFilters ? t('make_filtered_offers_passive') : t('make_all_offers_passive'),
        actionHandler(selectedRows) {
          onShowMakeFilteredOffersPassiveModal(selectedRows);
        },
        isSelectionRequired: false,
      },
  ].filter(Boolean);
};

export const getTourSteps = ({ t }) => {
  return [
    {
      selector: '.standard-akinon-table-header__title',
      content: (
        <>
          <Typography.Title level={4}>{t('offer_pool_guide_step_1_title')}</Typography.Title>
          <Typography.Text>{t('offer_pool_guide_step_1_desc')}</Typography.Text>
        </>
      ),
    },
    {
      selector: '.filter-options',
      content: (
        <>
          <Typography.Title level={4}>{t('offer_pool_guide_step_2_title')}</Typography.Title>
          <Typography.Text>{t('offer_pool_guide_step_2_desc')}</Typography.Text>
        </>
      ),
    },
    {
      selector: '.standard-akinon-table-header__export-option',
      content: (
        <>
          <Typography.Title level={4}>{t('offer_pool_guide_step_3_title')}</Typography.Title>
          <Typography.Text>{t('offer_pool_guide_step_3_desc')}</Typography.Text>
        </>
      ),
    },
    {
      selector: '.app-layout__page-meta-action:first-child',
      content: (
        <>
          <Typography.Title level={4}>{t('offer_pool_guide_step_4_title')}</Typography.Title>
          <Typography.Text>{t('offer_pool_guide_step_4_desc')}</Typography.Text>
        </>
      ),
    },
  ];
};
