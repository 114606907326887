import { createSelectOptions } from '@common/index';
import { UserRole } from '@constants/auth';
import { getIsNullBooleanAttributeOptions, WidgetType } from '@constants/commontypes';
import { useCategoryTreeData } from '@utils/hooks/useCategoryTreeData';
import { useUserRole } from '@utils/hooks/useUserRole';

import { StaticFilterKey } from '../common';

export const useStaticFilters = ({ t, dataSources, isDataSourcesLoading }) => {
  const userRole = useUserRole();

  const isSuperUser = userRole === UserRole.SUPER_USER;
  const { categoryTreeData, isCategoriesLoading } = useCategoryTreeData({
    generateTreeDataOptions: {
      valueKey: 'path',
    },
  });

  return {
    fields: [
      isSuperUser && {
        name: StaticFilterKey.DATASOURCE,
        widget: WidgetType.SELECT,
        showSearch: true,
        optionFilterProp: 'label',
        loading: isDataSourcesLoading,
        placeholder: t('seller'),
        options: createSelectOptions(dataSources, {
          labelKey: 'name',
          valueKey: 'id',
        }),
      },
      {
        name: StaticFilterKey.NAME,
        widget: WidgetType.INPUT,
        placeholder: t('product_name'),
      },
      {
        name: StaticFilterKey.SKU,
        widget: WidgetType.INPUT,
        placeholder: t('sku'),
      },
      {
        name: StaticFilterKey.BASE_CODE,
        widget: WidgetType.INPUT,
        placeholder: t('basecode'),
      },
      {
        name: StaticFilterKey.PRODUCT_ISNULL,
        widget: WidgetType.CHECKBOX_GROUP,
        placeholder: t('offer.product.related'),
        options: [{ label: t('offer.product.related'), value: false }],
      },
      {
        name: StaticFilterKey.CATEGORY,
        widget: WidgetType.TREESELECT,
        placeholder: t('category'),
        showSearch: true,
        treeDefaultExpandAll: true,
        virtual: false,
        style: { width: '100%' },
        loading: isCategoriesLoading,
        dropdownStyle: { maxHeight: 400, overflow: 'auto' },
        treeData: categoryTreeData,
        treeNodeFilterProp: 'title',
        allowClear: true,
      },
      {
        name: StaticFilterKey.IMAGE_ISNULL,
        widget: WidgetType.SELECT,
        showSearch: true,
        optionFilterProp: 'label',
        placeholder: t('product_image'),
        options: getIsNullBooleanAttributeOptions({ t }),
      },
    ].filter(Boolean),
  };
};
