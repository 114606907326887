import AkinonBox from '@components/AkinonBox';
import AkinonButton from '@components/AkinonButton';
import AkinonFlex from '@components/AkinonFlex';
import AkinonForm from '@components/AkinonForm';
import AkinonFormItem from '@components/AkinonFormItem';
import AkinonSpin from '@components/AkinonSpin';
import { UserRole } from '@constants/auth';
import { verticalFormItemLayout } from '@constants/layoutTypes';
import { Color } from '@constants/theme';
import { useUserRole } from '@utils/hooks/useUserRole';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import AgreementBox from '../common/AgreementBox';
import DraggerInputWithLabel from '../common/DraggerInputWithLabel';

const DocumentsForm = ({ initialValues = {}, isLoading, handleSubmit: onSubmit }) => {
  const { t } = useTranslation('Finance');
  const { control, handleSubmit } = useForm();
  const userRole = useUserRole();

  const uploadedFiles = Object.entries(initialValues?.extras?.files ?? {}).reduce(
    (acc, [key, value]) => {
      acc[key] = { base64Data: [{ url: value, uid: key }], file: { name: key } };
      return acc;
    },
    {}
  );

  const commonProps = {
    multiple: false,
    maxCount: 1,
    beforeUpload: () => {
      return false;
    },
    showUploadList: {
      showRemoveIcon: false,
    },
    type: 'file',
  };

  const getDefaultList = (key) => {
    return uploadedFiles?.[key]?.base64Data?.map((f) => ({
      name: f.url || f.uid,
      url: f.url,
    }));
  };

  return (
    <AkinonFlex className="gap-6 flex-col md:flex-row">
      <AkinonBox
        title={t('documents')}
        description={t('documents_description')}
        bannerColor={Color.WILD_WATERMELON}
        shadow
        className="box-primary form-box seller-management-detail__form-box"
      >
        <AkinonSpin spinning={isLoading}>
          {!isLoading && (
            <AkinonForm layout="vertical" {...verticalFormItemLayout}>
              <AkinonFormItem
                name="extras.files.company_form"
                label={t('current_form')}
                control={control}
              >
                <DraggerInputWithLabel
                  placeholder={t('current_form')}
                  defaultFileList={getDefaultList('company_form')}
                  aria-label="company_form"
                  {...commonProps}
                />
              </AkinonFormItem>
              <AkinonFormItem
                name="extras.files.marketplace_contract"
                label={t('marketplace_contract')}
                control={control}
              >
                <DraggerInputWithLabel
                  placeholder={t('marketplace_contract')}
                  defaultFileList={getDefaultList('marketplace_contract')}
                  aria-label="marketplace_contract"
                  {...commonProps}
                />
              </AkinonFormItem>
              <AkinonFormItem
                name="extras.files.signature_circular"
                label={t('signature_circulars')}
                control={control}
              >
                <DraggerInputWithLabel
                  placeholder={t('signature_circulars')}
                  defaultFileList={getDefaultList('signature_circular')}
                  aria-label="signature_circular"
                  {...commonProps}
                />
              </AkinonFormItem>
              <AkinonFormItem
                name="extras.files.tax_certificate"
                label={t('trade_sign')}
                control={control}
              >
                <DraggerInputWithLabel
                  placeholder={t('trade_sign')}
                  defaultFileList={getDefaultList('tax_certificate')}
                  aria-label="tax_certificate"
                  {...commonProps}
                />
              </AkinonFormItem>
              <AkinonFormItem
                name="extras.files.activity_certificate"
                label={t('operating_certificate')}
                control={control}
              >
                <DraggerInputWithLabel
                  placeholder={t('operating_certificate')}
                  defaultFileList={getDefaultList('activity_certificate')}
                  aria-label="activity_certificate"
                  {...commonProps}
                />
              </AkinonFormItem>
              <AkinonFormItem
                name="extras.files.commercial_registry_gazette"
                label={t('registry_gazette')}
                control={control}
              >
                <DraggerInputWithLabel
                  placeholder={t('registry_gazette')}
                  defaultFileList={getDefaultList('commercial_registry_gazette')}
                  aria-label="commercial_registry_gazette"
                  {...commonProps}
                />
              </AkinonFormItem>
              <AkinonButton htmlType="submit" type="primary" onClick={handleSubmit(onSubmit)}>
                {t('submit').toUpperCase()}
              </AkinonButton>
            </AkinonForm>
          )}
        </AkinonSpin>
      </AkinonBox>
      {userRole === UserRole.SELLER && <AgreementBox />}
    </AkinonFlex>
  );
};

export default DocumentsForm;
