import { useDynamicFilterFields } from '@utils/hooks/useDynamicFilterFields';

import { useStaticFilters } from './useStaticFilters';

const useFilterFields = ({
  t,
  attributes,
  isAttributesLoading,
  dataSources,
  isDataSourcesLoading,
}) => {
  const dynamicFields = useDynamicFilterFields({ attributes, isAttributesLoading });
  const staticFields = useStaticFilters({
    t,
    dataSources,
    isDataSourcesLoading,
  });

  return {
    dynamic: dynamicFields,
    static: staticFields,
  };
};

export default useFilterFields;
