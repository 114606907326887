import { useDynamicFilterFields } from '@utils/hooks/useDynamicFilterFields';

import useStaticFilterFields from './useStaticFilterFields';

const useFilterFields = ({
  t,
  attributes,
  categoryTreeData,
  isCategoriesLoading,
  isAttributesLoading,
}) => {
  const dynamicFields = useDynamicFilterFields({ attributes, isAttributesLoading });
  const staticFields = useStaticFilterFields({
    t,
    categoryTreeData,
    isCategoriesLoading,
  });

  return {
    dynamic: dynamicFields,
    static: staticFields,
  };
};

export default useFilterFields;
