import RootErrorBoundary from '@components/ErrorBoundry/RootErrorBoundary';
import ProtectedRoute from '@components/ProtectedRoute';
import PublicRoute from '@components/PublicRoute';
import ShowForRole from '@components/ShowForRole';
import { RouteUrls } from '@constants/routeUrls';
import Dashboard from '@pages/Dashboard';
import Download from '@pages/Download';
import ExternalDownload from '@pages/Download/ExternalDownload';
import AgreementDetail from '@pages/Finance/Agreements/AgreementDetail';
import AgreementList from '@pages/Finance/Agreements/AgreementList';
import FinanceDashboard from '@pages/Finance/FinanceDashboard';
import FinanceLanding from '@pages/Finance/FinanceLanding';
import ReconciliationDetail from '@pages/Finance/Reconciliations/ReconciliationDetail';
import ReconciliationListing from '@pages/Finance/Reconciliations/ReconciliationListing';
// import ReconciliationTransactions from '@pages/Finance/ReconciliationTransactions';
import SellerManagementDetail from '@pages/Finance/SellerManagement/SellerManagementDetail';
import SellerManagementList from '@pages/Finance/SellerManagement/SellerManagementList';
import ImportExportOperations from '@pages/ImportExportOperations';
import CampaignDetail from '@pages/Marketing/BasketOfferDetail/Campaigns/CampaignDetail';
import CampaignList from '@pages/Marketing/BasketOfferDetail/Campaigns/CampaignList';
import AddEditCollection from '@pages/Marketing/BasketOfferDetail/Collections/AddEditCollection';
import CollectionList from '@pages/Marketing/BasketOfferDetail/Collections/CollectionList';
import MarketingLanding from '@pages/Marketing/MarketingLanding';
import MessagesLanding from '@pages/Messages/MessagesLanding';
import OrderAnswer from '@pages/Messages/OrderAnswer';
import OrderMessages from '@pages/Messages/OrderMessages';
import ProductAnswer from '@pages/Messages/ProductAnswer';
import ProductMessages from '@pages/Messages/ProductMessages';
import QuickReplies from '@pages/Messages/QuickReplies';
import QuickReply from '@pages/Messages/QuickReplies/QuickReply';
import NotFound from '@pages/NotFound';
import NotificationList from '@pages/NotificationManagement/NotificationList';
import NotificationsForm from '@pages/NotificationManagement/NotificationsForm';
import CancellationRequests from '@pages/Orders/CancellationRequests';
import OrderDetail from '@pages/Orders/OrderDetail';
import OrderList from '@pages/Orders/OrderList';
import OrdersLanding from '@pages/Orders/OrdersLanding';
import PackageDetail from '@pages/Orders/Packages/PackageDetail';
import PackageList from '@pages/Orders/Packages/PackageList';
import PackagesLanding from '@pages/Orders/Packages/PackagesLanding';
import PackagesWaitingForCargo from '@pages/Orders/Packages/PackagesWaitingForCargo';
import PackagesWaitingForDelivery from '@pages/Orders/Packages/PackagesWaitingForDelivery';
import AddProductsFromCatalog from '@pages/ProductsAndCategories/AddProductsFromCatalog';
import BulkActionsLanding from '@pages/ProductsAndCategories/BulkActions/BulkActionsLanding';
import BulkActionsOffers from '@pages/ProductsAndCategories/BulkActions/BulkActionsOffers';
import InventoryManagementLanding from '@pages/ProductsAndCategories/InventoryManagementLanding';
import OfferListApproved from '@pages/ProductsAndCategories/OfferList/Approved';
import OfferDetailApproved from '@pages/ProductsAndCategories/OfferList/ApprovedDetail';
import OfferDetail from '@pages/ProductsAndCategories/OfferList/OfferDetail';
import OfferListPending from '@pages/ProductsAndCategories/OfferList/Pending';
import OfferListRejected from '@pages/ProductsAndCategories/OfferList/Rejected';
import OfferListLanding from '@pages/ProductsAndCategories/OfferListLanding';
import PriceDetail from '@pages/ProductsAndCategories/Prices/PriceDetail';
import PriceList from '@pages/ProductsAndCategories/Prices/PriceList';
import ProductDetail from '@pages/ProductsAndCategories/ProductDetail';
import ProductForm from '@pages/ProductsAndCategories/ProductForm';
import ProductPool from '@pages/ProductsAndCategories/ProductPool';
import ProductRevisionsLanding from '@pages/ProductsAndCategories/ProductRevisionsLanding';
import ProductsAndCategoriesLanding from '@pages/ProductsAndCategories/ProductsAndCategoriesLanding';
import StockDetail from '@pages/ProductsAndCategories/Stocks/StockDetail';
import StockList from '@pages/ProductsAndCategories/Stocks/StockList';
import LanguageSettings from '@pages/Profile/LanguageSettings';
import UserProfile from '@pages/Profile/UserProfile';
import ForgotPassword from '@pages/PublicRoutes/ForgotPassword';
import ResetPassword from '@pages/PublicRoutes/ResetPassword';
import SignIn from '@pages/PublicRoutes/SignIn';
import SignInCallback from '@pages/PublicRoutes/SignInCallback';
import ReportingLanding from '@pages/Reporting/ReportingLanding';
import SalesPerformance from '@pages/Reporting/SalesPerformance';
import SellerPerformance from '@pages/Reporting/SellerPerformance';
import Root from '@pages/Root';
import StoreManagement from '@pages/SellerStoreManagement';
import StoreManagementList from '@pages/SellerStoreManagement/StoreManagementList';
import AccessTokenList from '@pages/Settings/AccessTokens/AccessTokenList';
import AttributeSetAndCategoryMapping from '@pages/Settings/AttributeSetAndCategoryMapping';
import DeliverySettingsForm from '@pages/Settings/DeliverySettings/DeliverySettingForm';
import DeliverySettingsList from '@pages/Settings/DeliverySettings/DeliverySettingsList';
import DynamicSettings from '@pages/Settings/DynamicSettings';
import DynamicSettingsForm from '@pages/Settings/DynamicSettings/DynamicSettingsForm';
import IntegrationErrorsList from '@pages/Settings/IntegrationErrors/IntegrationErrorsList';
import SellerOfferRejectReasons from '@pages/Settings/SellerOfferRejectReasons';
import SellerStateTransitions from '@pages/Settings/SellerStateTransitions';
import SellerCreateUser from '@pages/Settings/SellerUserManagement/SellerCreateUser';
import SellerEditUser from '@pages/Settings/SellerUserManagement/SellerEditUser';
import SellerUserList from '@pages/Settings/SellerUserManagement/SellerUserList';
import SettingsLanding from '@pages/Settings/SettingsLanding';
import SsoSettingsForm from '@pages/Settings/SsoProviders/SsoProviderForm';
import SsoProviderList from '@pages/Settings/SsoProviders/SsoProviderList';
import UserManagementForm from '@pages/Settings/UserManagement/UserManagementForm';
import UserManagementListing from '@pages/Settings/UserManagement/UserManagementList';
import StockLocationList from '@pages/StockLocation';
import StockLocationDetail from '@pages/StockLocation/StockLocationDetail';
import SupportTicketDetail from '@pages/Support/SupportTicketDetail';
import SupportTicketForm from '@pages/Support/SupportTicketForm';
import SupportTicketList from '@pages/Support/SupportTicketList';
import SystemError from '@pages/SystemError';
import UnexpectedError from '@pages/UnexpectedError';
import * as Sentry from '@sentry/react';
import { createBrowserRouter, Navigate } from 'react-router-dom';

import AppShellFullPageAppGuard from './guards/AppShellFullPageAppGuard';
import AppLayout from './layouts/AppLayout';
import AppShellLayout from './layouts/AppShellLayout';
import BaseLayout from './layouts/BaseLayout';

/**
 * @type {import('react-router-dom').RouteObject[]}
 */
export const routes = [
  {
    element: <BaseLayout />,
    errorElement: <RootErrorBoundary />,
    children: [
      {
        path: RouteUrls.root,
        element: <Root />,
      },
      {
        element: <PublicRoute />,
        children: [
          {
            path: RouteUrls.public.signIn,
            element: <SignIn />,
          },
          {
            path: RouteUrls.public.signInCallback,
            element: <SignInCallback />,
          },
          {
            path: RouteUrls.public.forgotPassword,
            element: <ForgotPassword />,
          },
          {
            path: RouteUrls.public.resetPassword,
            element: <ResetPassword />,
          },
        ],
      },
      {
        element: <AppShellLayout />,
        children: [
          {
            element: <ProtectedRoute />,
            children: [
              {
                element: <AppLayout />,
                children: [
                  {
                    path: RouteUrls.productsAndCategories.landing,
                    element: <ProductsAndCategoriesLanding />,
                  },
                  {
                    path: RouteUrls.productsAndCategories.addProductsFromCatalog,
                    element: <AddProductsFromCatalog />,
                  },
                  {
                    path: RouteUrls.productsAndCategories.productRevisions.landing,
                    element: <ProductRevisionsLanding />,
                  },
                  {
                    path: RouteUrls.productsAndCategories.inventoryManagement.landing,
                    element: <InventoryManagementLanding />,
                  },
                  {
                    path: RouteUrls.productsAndCategories.inventoryManagement.prices.landing,
                    element: <PriceList />,
                  },
                  {
                    path: RouteUrls.productsAndCategories.inventoryManagement.prices.priceDetail,
                    element: <PriceDetail />,
                  },
                  {
                    path: RouteUrls.productsAndCategories.inventoryManagement.stocks.landing,
                    element: <StockList />,
                  },
                  {
                    path: RouteUrls.productsAndCategories.inventoryManagement.stocks.stockDetail,
                    element: <StockDetail />,
                  },
                  {
                    path: RouteUrls.productsAndCategories.products.productPool,
                    element: <ProductPool />,
                  },
                  {
                    path: RouteUrls.productsAndCategories.products.detail,
                    element: <ProductDetail />,
                  },
                  {
                    path: RouteUrls.productsAndCategories.products.productForm,
                    element: <ProductForm />,
                  },
                  {
                    path: RouteUrls.productsAndCategories.offerList.landing,
                    element: <OfferListLanding />,
                  },
                  {
                    path: RouteUrls.productsAndCategories.offerList.pendingApproval,
                    element: <OfferListPending />,
                  },
                  {
                    path: RouteUrls.productsAndCategories.offerList.rejected,
                    element: <OfferListRejected />,
                  },
                  {
                    path: RouteUrls.productsAndCategories.offerList.approved,
                    element: <OfferListApproved />,
                  },
                  {
                    path: RouteUrls.productsAndCategories.offerList.detail,
                    element: <OfferDetail />,
                  },
                  {
                    path: RouteUrls.productsAndCategories.offerList.approvedDetail,
                    element: <OfferDetailApproved />,
                  },
                  {
                    path: RouteUrls.productsAndCategories.bulkActions.landing,
                    element: <BulkActionsLanding />,
                  },
                  {
                    path: RouteUrls.productsAndCategories.bulkActions.offers,
                    element: <BulkActionsOffers />,
                  },
                  {
                    path: RouteUrls.order.landing,
                    element: <OrdersLanding />,
                  },
                  {
                    path: RouteUrls.order.ordersList,
                    element: <OrderList />,
                  },
                  {
                    path: RouteUrls.order.orderDetail,
                    element: <OrderDetail />,
                  },
                  {
                    path: RouteUrls.order.cancellationRequests,
                    element: <CancellationRequests />,
                  },
                  {
                    path: RouteUrls.order.packages.landing,
                    element: <PackagesLanding />,
                  },
                  {
                    path: RouteUrls.order.packages.packageList,
                    element: <PackageList />,
                  },
                  {
                    path: RouteUrls.order.packages.packageDetail,
                    element: <PackageDetail />,
                  },
                  {
                    path: RouteUrls.order.packages.packagesWaitingForCargo,
                    element: <PackagesWaitingForCargo />,
                  },
                  {
                    path: RouteUrls.order.packages.packagesWaitingForDelivery,
                    element: <PackagesWaitingForDelivery />,
                  },
                  {
                    path: RouteUrls.finance.landing,
                    element: <FinanceLanding />,
                  },
                  {
                    path: RouteUrls.finance.agreements,
                    element: <AgreementList />,
                  },
                  {
                    path: RouteUrls.finance.agreementDetail,
                    element: <AgreementDetail />,
                  },
                  {
                    path: RouteUrls.finance.dashboard,
                    element: <FinanceDashboard />,
                  },
                  {
                    path: RouteUrls.finance.reconciliations.landing,
                    element: <ReconciliationListing />,
                  },
                  {
                    path: RouteUrls.finance.reconciliations.detail,
                    element: <ReconciliationDetail />,
                  },
                  {
                    path: RouteUrls.finance.sellerManagement.landing,
                    element: (
                      <ShowForRole
                        superUser={<SellerManagementList />}
                        seller={<SellerManagementDetail />}
                      />
                    ),
                  },
                  {
                    path: RouteUrls.finance.sellerManagement.sellerDetail,
                    element: <SellerManagementDetail />,
                  },
                  // {
                  //   path: RouteUrls.finance.reconciliationTransactions,
                  //   element: <ReconciliationTransactions />,
                  // },
                  {
                    path: RouteUrls.reporting.landing,
                    element: <ReportingLanding />,
                  },
                  {
                    path: RouteUrls.reporting.salesPerformance,
                    element: <SalesPerformance />,
                  },
                  {
                    path: RouteUrls.reporting.sellerPerformance,
                    element: <SellerPerformance />,
                  },
                  {
                    path: RouteUrls.marketing.landing,
                    element: <MarketingLanding />,
                  },
                  {
                    path: RouteUrls.marketing.productCollections.landing,
                    element: <CollectionList />,
                  },
                  {
                    path: RouteUrls.marketing.productCollections.form,
                    element: <AddEditCollection />,
                  },
                  {
                    path: RouteUrls.marketing.productCollections.newCollection,
                    element: <AddEditCollection />,
                  },
                  {
                    path: RouteUrls.marketing.campaigns.landing,
                    element: <CampaignList />,
                  },
                  {
                    path: RouteUrls.marketing.campaigns.form,
                    element: <CampaignDetail />,
                  },
                  {
                    path: RouteUrls.messages.landing,
                    element: <MessagesLanding />,
                  },
                  {
                    path: RouteUrls.messages.productMessages,
                    element: <ProductMessages />,
                  },
                  {
                    path: RouteUrls.messages.productMessagesForm,
                    element: <ProductAnswer />,
                  },
                  {
                    path: RouteUrls.messages.orderMessages,
                    element: <OrderMessages />,
                  },
                  {
                    path: RouteUrls.messages.orderMessagesForm,
                    element: <OrderAnswer />,
                  },
                  {
                    path: RouteUrls.messages.quickReplies,
                    element: <QuickReplies />,
                  },
                  {
                    path: RouteUrls.messages.quickReply,
                    element: <QuickReply />,
                  },
                  {
                    path: RouteUrls.messages.quickRepliesForm,
                    element: <QuickReply />,
                  },
                  {
                    path: RouteUrls.settings.landing,
                    element: <SettingsLanding />,
                  },
                  {
                    path: RouteUrls.settings.userManagement,
                    element: <UserManagementListing />,
                  },
                  {
                    path: RouteUrls.settings.userManagementForm,
                    element: <UserManagementForm />,
                  },
                  {
                    path: RouteUrls.settings.sellerUserManagementSettings,
                    element: <SellerUserList />,
                  },
                  {
                    path: RouteUrls.settings.sellerUserManagementSettingsForm,
                    element: <SellerCreateUser />,
                  },
                  {
                    path: RouteUrls.notificationManagement.form,
                    element: <NotificationsForm />,
                  },
                  {
                    path: RouteUrls.notificationManagement.detail,
                    element: <NotificationsForm />,
                  },
                  {
                    path: RouteUrls.settings.dynamicSettings,
                    element: <DynamicSettings />,
                  },
                  {
                    path: RouteUrls.settings.dynamicSettingsForm,
                    element: <DynamicSettingsForm />,
                  },
                  {
                    path: RouteUrls.settings.deliverySettingsShipmentSettings,
                    element: <DeliverySettingsList />,
                  },
                  {
                    path: RouteUrls.settings.deliverySettingsShipmentSettingsDetail,
                    element: <DeliverySettingsForm />,
                  },
                  {
                    path: RouteUrls.settings.sellerOfferRejectReasons,
                    element: <SellerOfferRejectReasons />,
                  },
                  {
                    path: RouteUrls.settings.attributeSetAndCategoryMapping,
                    element: <AttributeSetAndCategoryMapping />,
                  },
                  {
                    path: RouteUrls.settings.sellerStateTransitions,
                    element: <SellerStateTransitions />,
                  },
                  {
                    path: RouteUrls.settings.sellerEditUser,
                    element: <SellerEditUser />,
                  },
                  {
                    path: RouteUrls.settings.accessTokens,
                    element: <AccessTokenList />,
                  },
                  {
                    path: RouteUrls.settings.integrationErrors,
                    element: <IntegrationErrorsList />,
                  },
                  {
                    path: RouteUrls.settings.ssoLoginSettingsUrl,
                    element: <SsoProviderList />,
                  },
                  {
                    path: RouteUrls.settings.ssoLoginSettingsFormUrl,
                    element: <SsoSettingsForm />,
                  },
                  {
                    path: RouteUrls.support.landing,
                    element: <SupportTicketList />,
                  },
                  {
                    path: RouteUrls.support.supportTicketForm,
                    element: <SupportTicketForm />,
                  },
                  {
                    path: RouteUrls.support.supportTicketDetail,
                    element: <SupportTicketDetail />,
                  },
                  {
                    path: RouteUrls.notificationManagement.landing,
                    element: <NotificationList />,
                  },
                  {
                    path: RouteUrls.profile.languageSettings,
                    element: <LanguageSettings />,
                  },
                  {
                    path: RouteUrls.profile.activeUser,
                    element: <UserProfile />,
                  },
                  {
                    path: RouteUrls.importExportOperations.landing,
                    element: <ImportExportOperations />,
                  },
                  {
                    path: RouteUrls.sellerStoreManagement.detail,
                    element: <StoreManagement />,
                  },
                  {
                    path: RouteUrls.sellerStoreManagement.landing,
                    element: (
                      <ShowForRole
                        superUser={<StoreManagementList />}
                        seller={<StoreManagement />}
                      />
                    ),
                  },
                  {
                    path: RouteUrls.download.landing,
                    element: <Download />,
                  },
                  {
                    path: RouteUrls.dashboard.landing,
                    element: <Dashboard />,
                  },
                  {
                    path: RouteUrls.stockLocations.landing,
                    element: <StockLocationList />,
                  },
                  {
                    path: RouteUrls.stockLocations.detail,
                    element: <StockLocationDetail />,
                  },
                  {
                    path: RouteUrls.stockLocations.create,
                    element: <StockLocationDetail />,
                  },
                  {
                    path: RouteUrls.download.export,
                    element: <ExternalDownload />,
                  },
                  {
                    path: RouteUrls.appShell.app,
                    element: <AppShellFullPageAppGuard />,
                  },
                  {
                    path: RouteUrls.errors.notFound,
                    element: <NotFound />,
                  },
                  {
                    path: RouteUrls.errors.systemError,
                    element: <SystemError />,
                  },
                  {
                    path: RouteUrls.errors.unexpectedError,
                    element: <UnexpectedError />,
                  },
                  {
                    path: '*',
                    element: <Navigate to={RouteUrls.errors.notFound} replace />,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

const createSentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = createSentryCreateBrowserRouter(routes);
