import { getRequestFiltersParams, getRequestPaginationParams } from '@common/index';
import AkinonFilter from '@components/AkinonFilter';
import { usePageFilters } from '@components/AkinonFilter/hooks/store/usePageFilters';
import AkinonTable from '@components/AkinonTable';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import BulkActionModal from '@components/BulkActionModal';
import { getProductOffersUrl } from '@constants/apiUrls';
import { defaultRowKey } from '@constants/index';
import { RouteUrls } from '@constants/routeUrls';
import { useIsMobile } from '@hooks/useIsMobile';
import { useProductOffersQuery } from '@pages/Messages/ProductAnswer/hooks/api/useProductOffersQuery';
import { OfferStatus } from '@pages/ProductsAndCategories/OfferList/common';
import { useProductsQuery } from '@pages/ProductsAndCategories/Stocks/StockDetail/hooks/api/useProductsQuery';
import useAppNavigate from '@root/hooks/useAppNavigate';
import useBulkAction from '@root/hooks/useBulkAction';
import { useAddProductToCollectionMutation } from '@services/api/hooks/useAddProductToCollectionMutation';
import { useCollectionDetailQuery } from '@services/api/hooks/useCollectionDetailQuery';
import { useDeleteProductFromCollectionMutation } from '@services/api/hooks/useDeleteProductFromCollectionMutation';
import { Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getProductListColumns, getTableActions } from '../common';
import useAddRemoveProductFromCollection from '../hooks/useAddRemoveProductFromCollection';
import useFilterFields from '../hooks/useFilterFields';
import CampaignProducts from './CampaignProducts';

const CollectionProducts = ({ collectionId }) => {
  const { t } = useTranslation(['Marketing', 'ProductsAndCategories']);
  const isMobile = useIsMobile();
  const navigate = useAppNavigate();
  const filters = usePageFilters();
  const [productPoolPagination, setProductPoolPagination] = usePagination();

  const filterFields = useFilterFields({ t });

  const { products, isProductsFetching } = useProductsQuery({});

  const { collectionDetail } = useCollectionDetailQuery({
    params: { collectionId },
    queryOptions: {
      enabled: Boolean(collectionId),
    },
  });

  const { productOffers, productOffersTotal } = useProductOffersQuery({
    requestConfig: {
      params: {
        not_in_collection: collectionId,
        product__in_collection: collectionId,
        ...getRequestPaginationParams(productPoolPagination),
        ...getRequestFiltersParams(filters),
        status: OfferStatus.approved,
      },
    },
  });

  const productOffersWithProductDetails = productOffers?.map((productOffer) => ({
    ...productOffer,
    product: products?.find((product) => product.id === productOffer.product),
  }));

  const { deleteProductFromCollectionAsync, isDeletingProductFromCollection } =
    useDeleteProductFromCollectionMutation();

  const { addProductToCollectionAsync, isAddingProductToCollection } =
    useAddProductToCollectionMutation();

  const { onAddSelectedItems, onDeleteSelectedItems } = useAddRemoveProductFromCollection({
    deleteProductFromCollectionAsync,
    addProductToCollectionAsync,
    collectionId,
  });

  const { onShowModal: onShowAddSelectedItemsModal, ...addSelectedItemsBulkActionProps } =
    useBulkAction({
      bulkActionMutate: onAddSelectedItems,
    });

  const { onShowModal: onShowDeleteItemsModal, ...deleteItemsBulkActionProps } = useBulkAction({
    bulkActionMutate: onDeleteSelectedItems,
  });

  const productsTableColumns = getProductListColumns({
    t,
    addProductToCollectionAsync,
    collectionId,
    isMobile,
  });

  const tableActions = getTableActions({
    t,
    onShowAddSelectedItemsModal,
  });

  const onRow = (record) => ({
    onClick: () => {
      navigate(RouteUrls.productsAndCategories.offerList.approvedDetail, {
        offerId: record.id,
      });
    },
  });

  return (
    <div className="campaign-detail">
      <BulkActionModal
        {...addSelectedItemsBulkActionProps}
        modalProps={{
          title: t('Marketing:bulk_add_selected_items'),
          description: t('Marketing:bulk_add_selected_items_desc'),
        }}
        progressModalProps={{ title: t('Marketing:bulk_add_selected_items') }}
      />
      <BulkActionModal
        {...deleteItemsBulkActionProps}
        modalProps={{
          title: t('Marketing:bulk_delete_selected_items'),
          description: t('Marketing:bulk_delete_selected_items_desc'),
        }}
        progressModalProps={{ title: t('Marketing:bulk_delete_selected_items') }}
      />
      <div>
        <Row>
          <Col span={24}>
            <AkinonFilter
              title={t('filters')}
              filterFields={filterFields}
              total={productOffersTotal}
            />
          </Col>
        </Row>
        <Row span={24} gutter={16} className="campaign-detail__products">
          <Col span={12}>
            <AkinonTable
              title={t('ProductsAndCategories:offer.list')}
              columns={productsTableColumns}
              dataSource={productOffersWithProductDetails}
              optionsUrl={getProductOffersUrl}
              loading={
                isDeletingProductFromCollection || isAddingProductToCollection || isProductsFetching
              }
              total={productOffersTotal}
              rowKey={defaultRowKey}
              pagination={productPoolPagination}
              setPagination={setProductPoolPagination}
              tableActions={tableActions}
              rowSelection={true}
              description={`${productOffersTotal || 0} ${t('results.found')}`}
              onRow={onRow}
            />
          </Col>
          <Col span={12}>
            <CampaignProducts
              collectionId={collectionId}
              collectionDetail={collectionDetail}
              filters={filters}
              deleteProductFromCollectionAsync={deleteProductFromCollectionAsync}
              onShowDeleteItemsModal={onShowDeleteItemsModal}
              isDeletingProductFromCollection={isDeletingProductFromCollection}
              isAddingProductToCollection={isAddingProductToCollection}
              onRow={onRow}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CollectionProducts;
