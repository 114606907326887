import { dateWith12HoursFormat } from '@common/date';
import { createSelectOptions } from '@common/index';
import AkinonButton from '@components/AkinonButton';
import CopyButton from '@components/CopyButton';
import Modal from '@components/Modal/Modal';
import Show from '@components/Show';
import { WidgetType } from '@constants/commontypes';
import { Tag, Tooltip, Typography } from 'antd';
import entries from 'lodash/entries';
import map from 'lodash/map';
import slice from 'lodash/slice';
import moment from 'moment';

export const ImportStatus = {
  PENDING: 1,
  PROCESSING: 2,
  SUCCEEDED: 3,
  PARTIALLY_SUCCEEDED: 4,
  FAILED: 5,
};

export const getImportStatusMap = ({ t }) => ({
  [ImportStatus.PENDING]: t('pending'),
  [ImportStatus.PROCESSING]: t('processing'),
  [ImportStatus.SUCCEEDED]: t('succeeded'),
  [ImportStatus.PARTIALLY_SUCCEEDED]: t('partially_succeeded'),
  [ImportStatus.FAILED]: t('failed'),
});

export const getTagColor = (status) => {
  switch (status) {
    case ImportStatus.PENDING:
      return 'blue';
    case ImportStatus.PROCESSING:
      return 'purple';
    case ImportStatus.SUCCEEDED:
      return 'green';
    case ImportStatus.PARTIALLY_SUCCEEDED:
      return 'lime';
    case ImportStatus.FAILED:
      return 'red';
    default:
      return 'default';
  }
};

export const ImportTableColumnDataIndex = {
  ID: 'id',
  REGISTRY: 'registry',
  STATUS: 'status',
  CREATED_AT: 'created_at',
  FILE: 'file',
  EFILE: 'efile',
};

export const StaticFilterKey = {
  ID: 'id',
  STATUS: 'status',
  REGISTRY: 'registry',
};

export const getStaticFilters = ({ t, registryOptions }) => {
  return {
    fields: [
      {
        name: StaticFilterKey.ID,
        placeholder: t('batch_request_id'),
        widget: WidgetType.INPUT,
      },
      {
        name: StaticFilterKey.STATUS,
        placeholder: t('file_status'),
        widget: WidgetType.SELECT,
        options: createSelectOptions(
          map(entries(getImportStatusMap({ t })), ([key, value]) => ({ key, value })),
          {
            valueKey: 'key',
            labelKey: 'value',
          }
        ),
      },
      {
        name: StaticFilterKey.REGISTRY,
        placeholder: t('transaction_type'),
        widget: WidgetType.SELECT,
        options: createSelectOptions(
          map(entries(registryOptions), ([key, value]) => ({ key, value })),
          {
            valueKey: 'key',
            labelKey: 'value',
          }
        ),
      },
    ],
  };
};
export const getImportTableColumns = ({ t, documentUrl, setDcoumentUrl, isDownloading }) => {
  const importStatusOptions = getImportStatusMap({ t });

  return [
    {
      title: t('batch_request_id'),
      dataIndex: ImportTableColumnDataIndex.ID,
      render(batch_request_id) {
        return (
          <CopyButton
            text={batch_request_id}
            label={
              <Tooltip title={batch_request_id}>
                {`${slice(batch_request_id, 0, 10).join('')}...`}
              </Tooltip>
            }
            isColumn
          />
        );
      },
    },
    {
      title: t('transaction_type'),
      dataIndex: ImportTableColumnDataIndex.REGISTRY,
      render(registry) {
        return <Tooltip title={registry}>{registry}</Tooltip>;
      },
    },
    {
      title: t('file_status'),
      dataIndex: ImportTableColumnDataIndex.STATUS,
      render(status, record) {
        const tagColor = getTagColor(status);
        return (
          <Tooltip
            title={`${t('total')}: ${record?.state?.total ?? '-'} / ${t('failed')}: ${record?.state?.failed ?? '-'} / ${t('success')}: ${record?.state?.succeeded ?? '-'}`}
          >
            <Tag color={tagColor}>{importStatusOptions[status]}</Tag>
          </Tooltip>
        );
      },
    },
    {
      title: t('uploaded_file'),
      dataIndex: ImportTableColumnDataIndex.FILE,
      render(fileUrl, rowData) {
        return (
          <Show when={fileUrl} fallback={'-'}>
            <AkinonButton
              loading={isDownloading && documentUrl === `imports/${rowData.id}/download/file`}
              htmlType="button"
              type="link"
              onClick={() => setDcoumentUrl(`imports/${rowData.id}/download/file`)}
            >
              {t('download')}
            </AkinonButton>
          </Show>
        );
      },
    },
    {
      title: t('created_at'),
      dataIndex: ImportTableColumnDataIndex.CREATED_AT,
      render(createdAt) {
        return (
          <Tooltip title={moment(createdAt).format(dateWith12HoursFormat)}>
            {moment(createdAt).format(dateWith12HoursFormat)}
          </Tooltip>
        );
      },
    },
    {
      title: t('error'),
      dataIndex: ImportTableColumnDataIndex.EFILE,
      render(fileUrl, rowData) {
        const status = rowData?.status;
        if (status === ImportStatus.FAILED) {
          return (
            <Typography.Paragraph
              ellipsis={{
                suffix: (
                  <Typography.Text
                    type="danger"
                    onClick={() => {
                      Modal.confirm({
                        content: JSON.stringify(rowData?.errors, null, 2),
                        closable: true,
                        okButtonProps: { hidden: true },
                        cancelButtonProps: { hidden: true },
                        maskClosable: true,
                      });
                    }}
                  >
                    {t('more')}
                  </Typography.Text>
                ),
              }}
            >
              {JSON.stringify(rowData?.errors, null, 2)}
            </Typography.Paragraph>
          );
        }
        return (
          <Show when={fileUrl} fallback={'-'}>
            <AkinonButton
              loading={isDownloading && documentUrl === `imports/${rowData.id}/download/efile`}
              htmlType="button"
              type="link"
              onClick={() => setDcoumentUrl(`imports/${rowData.id}/download/efile`)}
            >
              {t('download')}
            </AkinonButton>
          </Show>
        );
      },
    },
  ];
};
